import { Avatar, Stack, Typography, useTheme } from "@mui/material";
import {
  useGetCompanyLogoQuery,
  useGetMapSummaryQuery,
  useGetOriginationTabQuery,
} from "api/api-maps";
import placeholder from "assets/placeholder-company.png";
import { MapContext } from "pages/maps/map";
import React from "react";

interface CoverPageProps {}
const CoverPage: React.FC<CoverPageProps> = () => {
  const mapName = React.useContext(MapContext);

  const { data: mapSummary } = useGetMapSummaryQuery(mapName ?? "", {
    skip: mapName === undefined,
  });

  const { data: tabData, isLoading: isLoadingTabData } =
    useGetOriginationTabQuery(mapName ?? "", {
      skip: mapName === undefined,
    });

  const { account_type = "", name = "" } =
    tabData && "customer" in tabData ? tabData.customer : {};

  const { product } = mapSummary ?? {};

  const {
    data: companyLogo = {
      url: placeholder,
    },
  } = useGetCompanyLogoQuery(
    {
      map_name: mapName ?? "",
      account_type,
    },
    {
      skip: !mapName,
      refetchOnFocus: true,
      refetchOnReconnect: true,
      refetchOnMountOrArgChange: true,
    }
  );
  const theme = useTheme();
  return (
    <Stack
      height="100%"
      justifyContent="center"
      alignItems="center"
      width="100%"
      position="relative"
      id="cover-page"
      sx={{
        position: "relative",
        zIndex: 3,
      }}
    >
      <Stack
        direction="row"
        spacing={2}
        maxWidth="100%"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        ml="-9%"
      >
        <Avatar
          src={companyLogo?.url}
          sx={{
            width: "10rem",
            height: "10rem",
            clipPath: "polygon(10% -20%, 100% 0%, 120% 100%, 10% 120%)",
            boxShadow: "3px 1px 10px 15px #00000078",
            outline: `10px solid ${theme.palette.white.main}`,
          }}
        />
        <Stack spacing={2} pl={2}>
          <Typography
            variant="h2"
            fontSize={"1rem"}
          >{`${name} ${product === "factoring" ? "selling to" : "buying from"} ${tabData?.trade_partner?.name}`}</Typography>
          <Typography variant="h2">
            {product === "factoring"
              ? "Factoring Deal"
              : "Reverse Factoring Deal"}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default CoverPage;
