import { createApi } from "@reduxjs/toolkit/query/react";
import { NEW_USER, VITE_API_V2_URL } from "codes";
import { axiosBaseQuery } from "helpers/axiosbaseQuery";
import toProperCase from "helpers/propercase";
import {
  setAppState,
  setUserPermission,
} from "redux/reducers/appStatesReducer";
import type {
  AddContactSchema,
  CompanyUser,
  Contact,
  DEPARTMENT_NAMES,
  RegisterUser,
  RequiredDoc,
  UpdateContactSchema,
  User,
  UserDoc,
  UserPermission,
} from "types";
import { accountrtk } from "./api-accounts";
import { it } from "./api-it";
import type { Approver, CallAttendee } from "./api-users.d";

const formatCompanyUser = (usersInput: CompanyUser[]): any[] => {
  return usersInput.map((user) => {
    return {
      id: user.id,
      first_name: user.first_name,
      last_name: user.last_name,
      title: user.title,
      roles: user.roles?.map((role) => toProperCase(role))?.join(", "),
      mobile: user.mobile,
      email: user.email,
      available_role: user.available_role,
    };
  });
};

export const users_v2 = createApi({
  reducerPath: "users_v2",
  tagTypes: [
    "Users",
    "CompanyUsers",
    "CALL_ATTENDEES",
    "APPROVERS",
    "IDVerification",
    "USER_ACCESS_REQUIRED_DOCUMENTS",
    "USER_ACCESS_LEVELS",
    "PROFILE_PIC",
  ],
  baseQuery: axiosBaseQuery({
    baseUrl: VITE_API_V2_URL,
  }),
  endpoints: (builder) => ({
    getCurrentUser: builder.query<User, void>({
      query: () => ({
        url: `/user`,
        method: "get",
      }),
      providesTags: () => ["Users"],
      transformResponse: (response) => {
        return response?.data;
      },
    }),

    getContacts: builder.query<any[], void>({
      query: () => ({
        url: `/users/account-contacts`,
        method: "get",
      }),
      providesTags: ["Users", "CompanyUsers"],
      transformResponse: (response) => response?.data,
    }),

    getUsers: builder.query<any[], string>({
      query: (account_id) => ({
        url: `/accounts/${account_id}/contacts`,
        method: "get",
      }),
      providesTags: ["Users", "CompanyUsers"],
      transformResponse: (response) => response?.data,
    }),
    getUser: builder.query<User, null>({
      query: () => ({
        url: `/user`,
        method: "get",
      }),
      // Provides `User` by `id`.
      providesTags: ["Users", "CompanyUsers"],
      transformResponse: (response) => response.data,
    }),
    getAccountContacts: builder.query<any[], string>({
      query: (accountId: string) => ({
        url: `/accounts/${accountId}/contacts`,
        method: "get",
      }),
      providesTags: ["CompanyUsers", "IDVerification"],
      transformResponse: (response) => {
        return formatCompanyUser(response?.data);
      },
    }),

    getSingleAccountContact: builder.query<
      Contact,
      { accountId: string; contactId: string }
    >({
      query: ({ accountId, contactId }) => ({
        url: `/accounts/${accountId}/contacts/${contactId}`,
        method: "get",
      }),
      providesTags: () => ["CompanyUsers"],
      transformResponse: (response) => response?.data,
    }),

    addUser: builder.mutation<
      User,
      AddContactSchema & { type?: "IDVerification" }
    >({
      query: (data) => {
        const { account_id, files, permissions, ...rest } = data;
        const formData = new FormData();
        formData.append("first_name", rest.first_name);
        formData.append("last_name", rest.last_name);
        formData.append("email", rest.email);
        formData.append("mobile", rest.mobile);
        formData.append("title", rest.title);
        formData.append("director", rest.director);
        formData.append("to_invite", rest.to_invite);
        formData.append("primary_contact", rest.primary_contact);
        files?.forEach((file) => {
          formData.append(Object.keys(file)?.[0], file[Object.keys(file)?.[0]]);
        });
        permissions?.forEach((permission) => {
          formData.append(
            Object.keys(permission)?.[0],
            permission[Object.keys(permission)?.[0]]
          );
        });

        return {
          url: `/accounts/${account_id}/contacts`,
          method: "POST",
          data: formData,
        };
      },
      // Invalidates all User-type queries providing the `LIST` id - after all, depending of the sort order,
      // that newly created user could show up in any lists.
      invalidatesTags: ["CompanyUsers", "Users"],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          //For Lead Creation (Origination Module)
          dispatch(
            accountrtk.util.invalidateTags(["ACCOUNTS", "PARTNER_CONTACTS"])
          );
          if (arg?.type === "IDVerification") {
            dispatch(users_v2.util.invalidateTags(["IDVerification"]));
          }
        } catch (err) {
          //eslint-disable-next-line no-console
          console.log(err);
        }
      },
    }),

    getUserAccessLevels: builder.query<any[], void>({
      query: () => ({
        url: `/accounts/permissions`,
        method: "get",
      }),
      providesTags: ["USER_ACCESS_LEVELS"],
      transformResponse: (response) => response.data,
    }),

    addContact: builder.mutation<
      User,
      RegisterUser & { type?: "IDVerification" }
    >({
      query: (data) => {
        return {
          url: `/accounts/${data.account_id}/contacts`,
          method: "POST",
          data,
        };
      },
      invalidatesTags: ["IDVerification"],
    }),

    editContact: builder.mutation<
      User,
      { account_id: string; contact_id: string; data: RegisterUser }
    >({
      query: ({ account_id, contact_id, data }) => {
        return {
          url: `/accounts/${account_id}/contacts/${contact_id}`,
          method: "PUT",
          data,
        };
      },
      invalidatesTags: ["IDVerification"],
    }),

    updateContact: builder.mutation<
      User,
      {
        account_id: string;
        contact_id: string;
        data: Partial<UpdateContactSchema>;
      }
    >({
      query: ({ account_id, contact_id, data }) => {
        return {
          url: `/accounts/${account_id}/contacts/${contact_id}`,
          method: "PUT",
          data,
        };
      },
      invalidatesTags: ["CompanyUsers", "Users"],
    }),

    deleteContact: builder.mutation<
      any,
      { account_id: string; contact_id: string }
    >({
      query: ({ account_id, contact_id }) => {
        return {
          url: `/accounts/${account_id}/contacts/${contact_id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["IDVerification"],
    }),

    updateUser: builder.mutation<
      User,
      { account_id: string; user: Partial<User> }
    >({
      query(payload) {
        const { account_id, user } = payload;
        return {
          url: `/accounts/${account_id}/users/${user?.id}`,
          method: "PUT",
          data: user,
        };
      },
      invalidatesTags: ["CompanyUsers", "Users"],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            accountrtk.util.invalidateTags(["ACCOUNT_ONBOARDING_STATUS"])
          );
        } catch (err) {
          //eslint-disable-next-line no-console
          console.log(err);
        }
      },
    }),
    fetchUserDocuments: builder.query<
      Array<{ id: string; name: string; url: string; document_type: string }>,
      string
    >({
      query: (id: string) => ({
        url: `/users/${id}/documents`,
        method: "get",
      }),
      providesTags: ["Users", "CompanyUsers"],
      transformResponse: (response) => response.data,
    }),
    fetchUserProfilePic: builder.query<UserDoc, void>({
      query: () => ({
        url: `/users/documents/profile`,
        method: "get",
      }),
      providesTags: ["PROFILE_PIC"],
      transformResponse: (response) => response.data,
    }),
    addUserProfilePic: builder.mutation<UserDoc, FormData>({
      query: (data) => ({
        url: `/users/documents/profile`,
        method: "POST",
        data,
      }),
      invalidatesTags: ["PROFILE_PIC"],
    }),

    fetchUserPermissions: builder.query<UserPermission[], string>({
      query: (id: string) => ({
        url: `/users/${id}/permissions`,
        method: "get",
      }),
      providesTags: ["Users", "CompanyUsers"],
      transformResponse: (response) => {
        return response.data;
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        try {
          const { data } = await queryFulfilled;
          const userPermission = data;
          // eslint-disable-next-line no-extra-boolean-cast
          if (!Boolean(userPermission?.length)) {
            dispatch(setAppState(NEW_USER));
          } else {
            const APP_STATE = userPermission?.[0]?.account_state;
            dispatch(setAppState(APP_STATE));
          }
        } catch (err) {
          dispatch(setAppState(NEW_USER));
          dispatch(setUserPermission([]));
        }
      },
    }),
    fetchIncomlendPermissions: builder.query<
      {
        groups: any[];
        permissions: any[];
      },
      string
    >({
      query: (id: string) => ({
        url: `/users/${id}/permissions?type=internal`,
        method: "get",
      }),
      providesTags: ["Users", "CompanyUsers"],
      transformResponse: (response) => {
        return response.data;
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        try {
          await queryFulfilled;
        } catch (err) {
          dispatch(setAppState(NEW_USER));
          dispatch(setUserPermission([]));
        }
      },
    }),
    disableUser: builder.mutation<any, { user_id: string; is_active: string }>({
      query: (data) => {
        return {
          url: `/users/${data.user_id}/status/${data.is_active}`,
          method: "PUT",
          data,
        };
      },
      transformResponse: (response) => response?.data,
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        try {
          await queryFulfilled;
          dispatch(
            it.util.invalidateTags([
              "USERS",
              "USERS_EXTERNAL",
              "USERS_INTERNAL",
            ])
          );
        } catch (err) {
          dispatch(setAppState(NEW_USER));

          dispatch(setUserPermission([]));
        }
      },
    }),
    fetchUserAccessLevels: builder.query<
      string[],
      {
        account_id: string;
        contact_id: string;
      }
    >({
      query: ({ account_id, contact_id }) => ({
        url: `/accounts/${account_id}/contacts/${contact_id}/permissions`,
        method: "get",
      }),
      providesTags: ["USER_ACCESS_LEVELS"],
      transformResponse: (response) => response.data,
    }),
    modifyUserAccessLevels: builder.mutation<
      any,
      {
        account_id: string;
        contact_id: string;
        data: {
          [permission_name: string]: boolean;
        };
      }
    >({
      query: ({ account_id, contact_id, data }) => ({
        url: `/accounts/${account_id}/contacts/${contact_id}/permissions`,
        method: "patch",
        data,
      }),
      invalidatesTags: ["USER_ACCESS_LEVELS"],
    }),

    getCallAttendees: builder.query<CallAttendee[], null>({
      query: () => ({
        url: `/users/call-attendees`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["CALL_ATTENDEES"],
    }),
    getApprovers: builder.query<Approver[], DEPARTMENT_NAMES>({
      query: (department) => ({
        url: `/users/approvers?department=${department}`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["APPROVERS"],
    }),
    getUserAccessRequiredDocuments: builder.query<RequiredDoc[], string>({
      query: (account_id) => ({
        url: `/accounts/${account_id}/contacts/required-documents`,
        method: "get",
      }),
      providesTags: ["USER_ACCESS_REQUIRED_DOCUMENTS"],
      transformResponse: (response) => response.data,
    }),
    uploadUserAccessDocuments: builder.mutation<
      any,
      { contact_id: string; data: FormData }
    >({
      query: ({ contact_id, data }) => ({
        url: `/users/${contact_id}/documents`,
        method: "POST",
        data,
      }),
      invalidatesTags: ["USER_ACCESS_REQUIRED_DOCUMENTS"],
    }),
    deleteUserAccessDocuments: builder.mutation<
      any,
      { contact_id: string; document_id: string }
    >({
      query: ({ contact_id, document_id }) => ({
        url: `/users/${contact_id}/documents/${document_id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["USER_ACCESS_REQUIRED_DOCUMENTS"],
    }),
  }),
});

export const {
  useGetCurrentUserQuery,
  useGetUsersQuery,
  useGetUserQuery,
  useGetAccountContactsQuery,
  useGetSingleAccountContactQuery,
  useAddUserMutation,
  useUpdateUserMutation,
  useFetchIncomlendPermissionsQuery,
  useFetchUserDocumentsQuery,
  useFetchUserPermissionsQuery,
  useFetchUserProfilePicQuery,
  useDisableUserMutation,
  useGetCallAttendeesQuery,
  useGetApproversQuery,
  useAddContactMutation,
  useEditContactMutation,
  useDeleteContactMutation,
  useGetUserAccessRequiredDocumentsQuery,
  useGetUserAccessLevelsQuery,
  useFetchUserAccessLevelsQuery,
  useUpdateContactMutation,
  useUploadUserAccessDocumentsMutation,
  useModifyUserAccessLevelsMutation,
  useDeleteUserAccessDocumentsMutation,
  useLazyGetUserAccessRequiredDocumentsQuery,
  useAddUserProfilePicMutation,
  useGetContactsQuery,
} = users_v2;
