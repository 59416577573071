import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { useGetOriginationTabQuery } from "api/api-maps"; // Add this import statement
import MainCard from "components/Common/Cards/MainCard";
import SkeletonLoad from "components/Common/SkeletonLoad";
import { downloadStacksAsPdf } from "helpers/downloadDiv";
import useWidth from "helpers/useWidth";
import { MapContext } from "pages/maps/map";
import React, { useRef } from "react";

import Layout from "components/Common/Layout";
import InfoPanes from "./InfoPanes";
import PresentationTemplate from "./PresentationTemplate";
import CoverPage from "./PreviewPanes/CoverPage";
import CustomerPage from "./PreviewPanes/CustomerPage";
import OriginOfTheDeal from "./PreviewPanes/OriginOfTheDeal";
import ThankyouPage from "./PreviewPanes/ThankyouPage";
import TradeDetailsPage from "./PreviewPanes/TradeDetailsPage";
import TradePartnerPage from "./PreviewPanes/TradePartnerPage";

const CCPresentation: React.FC = () => {
  //The main purpose of this screen is to automatically draft the powerpoint presentation used by the RM during the Credit Committee meeting.
  const mapName = React.useContext(MapContext);
  const {
    data: tabData,
    isLoading: isLoadingTabData,
    refetch,
  } = useGetOriginationTabQuery(mapName ?? "", {
    skip: mapName === undefined,
  });
  const contentRef = useRef<HTMLDivElement>(null);

  const width = useWidth(contentRef);
  const panelWidth = width * 0.8;

  return (
    <Layout
      title="Credit Committee Presentation"
      headerConfig={{
        syncAction: refetch,
      }}
    >
      <Stack direction="row" spacing={2}>
        <Box flex={1} p={2}>
          {isLoadingTabData && <SkeletonLoad bars={10} />}
          {!isLoadingTabData && tabData && <InfoPanes {...tabData} />}
        </Box>
        <Box flex={1} p={2}>
          <Stack spacing={2} width={"100%"}>
            <Stack direction="row" spacing={2} width={"100%"}>
              <Stack spacing={2} width={"100%"}>
                <Typography variant="h3">Preview</Typography>
                <Divider
                  sx={{
                    width: "100%",
                  }}
                />
              </Stack>
              <LoadingButton
                variant="contained"
                onClick={
                  () => {
                    downloadStacksAsPdf({
                      stackIds: [
                        "cover",
                        "origin-of-the-deal",
                        "customer-page",
                        "trade-partner-page",
                        "trade-details-page",
                        "thankyou-page",
                      ],
                      fileName: "presentation.pdf",
                    });
                  }
                  // reactToPrintFn
                }
              >
                Download
              </LoadingButton>
            </Stack>
            <MainCard
              contentSX={{
                boxShadow: 2,
              }}
            >
              <Stack spacing={2} alignItems="center" ref={contentRef}>
                <PresentationTemplate width={panelWidth} id="cover">
                  <CoverPage />
                </PresentationTemplate>
                <PresentationTemplate
                  width={panelWidth}
                  id="origin-of-the-deal"
                >
                  <OriginOfTheDeal />
                </PresentationTemplate>
                <PresentationTemplate width={panelWidth} id="customer-page">
                  <CustomerPage />
                </PresentationTemplate>
                <PresentationTemplate
                  width={panelWidth}
                  id="trade-partner-page"
                >
                  <TradePartnerPage />
                </PresentationTemplate>
                <PresentationTemplate
                  width={panelWidth}
                  id="trade-details-page"
                >
                  <TradeDetailsPage />
                </PresentationTemplate>
                <PresentationTemplate
                  dark
                  width={panelWidth}
                  id="thankyou-page"
                >
                  <ThankyouPage />
                </PresentationTemplate>
              </Stack>
            </MainCard>
          </Stack>
        </Box>
      </Stack>
    </Layout>
  );
};

export default CCPresentation;
