import { Avatar, Stack, useTheme } from "@mui/material";
import LogoDesktop from "assets/logo.svg?react";
import presentationTemplate from "assets/presentation-template.png";
import React from "react";

interface PresentationTemplateProps {
  children: JSX.Element;
  dark?: boolean;
  id?: string;
  width: number;
}

const PresentationTemplate: React.FC<PresentationTemplateProps> = ({
  children,
  dark = false,
  id,
  width,
}) => {
  const theme = useTheme();
  const common_factor = 0.6;
  const a4_aspect_ratio = 1.414;

  const height = width * 0.868;

  return (
    <Stack
      sx={{
        borderRadius: 1,
        aspectRatio: "4/3",
        position: "relative",
        zIndex: 0,
        width: "100%",
        height,
        backgroundImage: `url(${presentationTemplate})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundColor: dark
          ? theme.palette.primary.main
          : theme.palette.white.main,
      }}
      id={id}
    >
      <Avatar
        variant="square"
        sx={{
          bgcolor: theme.palette.primary.main,
          width: "100%",
          height: "2%",
          borderTopLeftRadius: 5,
          borderTopRightRadius: 5,
        }}
      >
        {" "}
      </Avatar>

      <Stack direction="row">
        <Avatar
          variant="square"
          sx={{
            bgcolor: theme.palette.error.main,
            width: "1%",
            height: "100%",
            minHeight: height - 2 * 0.02 * height,
          }}
        >
          {" "}
        </Avatar>
        {children}
      </Stack>
      <Stack direction="row" height={"2%"}>
        <Avatar
          variant="square"
          sx={{
            bgcolor: "transparent",
            width: "70%",
            height: "100%",
            borderBottomLeftRadius: 5,
          }}
        >
          {" "}
        </Avatar>
        <Stack
          sx={{
            width: "30%",
            height: "100%",
            borderBottomRightRadius: 5,
            alignItems: "end",
          }}
        >
          <Avatar
            sx={{
              bgcolor: "transparent",
              mt: -6,
              mr: 2,
              width: "50%",
              position: "relative",
              zIndex: 2,
            }}
            variant="square"
          >
            <LogoDesktop width={"100%"} height="auto" />
          </Avatar>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default PresentationTemplate;
