import { createApi } from "@reduxjs/toolkit/query/react";
import { VITE_API_V2_URL } from "codes";
import { axiosBaseQuery } from "helpers/axiosbaseQuery";
import type { InvoiceStatus } from "types";
import type {
  AUMStats,
  GenerateInvoice,
  GeneratedInvoice,
  InvoiceStats,
  SOFRRate,
  TaxInvoice,
  TaxInvoiceData,
} from "./api-operations.d";

export interface MonthlyInvoiceStats {
  total_amount_financed: number;
  financed_per_buyer: {
    buyer: string;
    total_financed_amount: number;
    percentage: number;
  }[];
}

export interface DailyInvoiceStats {
  date: string;
  financed_per_buyer: {
    buyer: string;
    amount: number;
  }[];
}

export interface PlatformInvoiceStats {
  status: string;
  amount: number;
}

export const operations = createApi({
  reducerPath: "operations",
  tagTypes: [
    "INVOICE_STATUS",
    "ROLLS",
    "TAX_INVOICES",
    "SF_TAX_INVOICES",
    "TAX_INVOICE",
    "SOFR_RATES",
  ],
  baseQuery: axiosBaseQuery({
    baseUrl: `${VITE_API_V2_URL}/operations`,
  }),
  endpoints: (builder) => ({
    getInvoiceStatuses: builder.query<
      {
        [statusName: string]: InvoiceStatus[];
      },
      null
    >({
      query: () => ({ url: "/invoice-statuses", method: "get" }),
      transformResponse: (response) => response?.data,
      providesTags: ["INVOICE_STATUS"],
    }),
    getRolls: builder.query<
      {
        [statusName: string]: InvoiceStatus[];
      },
      null
    >({
      query: () => ({ url: "/invoice-rolls", method: "get" }),
      transformResponse: (response) => response?.data,
      providesTags: ["ROLLS"],
    }),
    getInvoiceStats: builder.query<InvoiceStats, string>({
      query: (invoice_id) => ({ url: `/invoice/${invoice_id}`, method: "get" }),
      transformResponse: (response) => response?.data,
    }),
    getMonthlyInvoiceStats: builder.query<MonthlyInvoiceStats, null>({
      query: () => ({
        url: `/invoices/monthly-financed`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
    }),
    getDailyInvoiceStats: builder.query<DailyInvoiceStats[], null>({
      query: () => ({
        url: `/invoices/monthly-financed-by-day`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
    }),
    getAUM: builder.query<AUMStats[], null>({
      query: () => ({
        url: `/invoices/assets-under-management`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
    }),
    getPlatformInvoiceStats: builder.query<PlatformInvoiceStats[], null>({
      query: () => ({
        url: `/invoices/available-on-platform`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
    }),

    getTaxInvoices: builder.query<TaxInvoice[], string>({
      query: (sf_invoice_name) => ({
        url: `/invoices/${sf_invoice_name}/tax-invoices`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["SF_TAX_INVOICES"],
    }),
    getTaxInvoice: builder.query<
      TaxInvoiceData,
      { sf_invoice_name: string; sf_tax_invoice_id: string }
    >({
      query: ({ sf_invoice_name, sf_tax_invoice_id }) => ({
        url: `/invoices/${sf_invoice_name}/tax-invoices/${sf_tax_invoice_id}`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
    }),
    generateTaxInvoice: builder.mutation<
      any,
      GenerateInvoice & { sf_invoice_name: string }
    >({
      query: (payload) => {
        const { sf_invoice_name, ...data } = payload;
        return {
          url: `/invoices/${sf_invoice_name}/tax-invoices`,
          method: "post",
          data,
        };
      },
      invalidatesTags: ["TAX_INVOICES", "SF_TAX_INVOICES"],
    }),
    getGeneratedTaxInvoices: builder.query<GeneratedInvoice[], void>({
      query: () => ({ url: `/tax-invoices`, method: "get" }),
      transformResponse: (response) => response?.data,
      providesTags: ["TAX_INVOICES"],
    }),
    getGeneratedTaxInvoice: builder.query<GeneratedInvoice, string>({
      query: (internal_name) => ({
        url: `/tax-invoices/${internal_name}`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["TAX_INVOICE"],
    }),
    getSofrRates: builder.query<SOFRRate[], { from?: string; to?: string }>({
      query: ({ from, to }) => ({
        url: `/sofr-rates${from && to ? `?from=${from}&to=${to}` : ""}`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["SOFR_RATES"],
    }),
    addSofrRate: builder.mutation<
      SOFRRate,
      {
        date: string; // YYYY-MM-DD
        rate: number;
      }
    >({
      query: (data) => ({
        url: `/sofr-rates`,
        method: "post",
        data,
      }),
      invalidatesTags: ["SOFR_RATES"],
    }),
  }),
});

export const {
  useGetInvoiceStatusesQuery,
  useGetRollsQuery,
  useGetInvoiceStatsQuery,
  useGetMonthlyInvoiceStatsQuery,
  useGetDailyInvoiceStatsQuery,
  useGetPlatformInvoiceStatsQuery,
  useGetTaxInvoicesQuery,
  useGetTaxInvoiceQuery,
  useGenerateTaxInvoiceMutation,
  useGetGeneratedTaxInvoicesQuery,
  useGetGeneratedTaxInvoiceQuery,
  useGetSofrRatesQuery,
  useAddSofrRateMutation,
  useGetAUMQuery,
} = operations;
