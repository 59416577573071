import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type { ResultsStrip } from "./sofrReducer.d";

// Define the initial state using that type
const initialState: { value: ResultsStrip[] } = {
  value: [],
};

export const sofrSlice = createSlice({
  name: "sofr",
  // `createSlice` will infer the state type from the `initialState` argument
  // Use the PayloadAction type to declare the contents of `action.payload`
  initialState,
  //CRUD
  reducers: {
    addSofrRates: (state, action: PayloadAction<ResultsStrip[]>) => {
      state.value = [...action.payload];
    },

    clearAll: () => initialState,
  },
});

export const { addSofrRates, clearAll } = sofrSlice.actions;

export default sofrSlice.reducer;
