import { createApi } from "@reduxjs/toolkit/query/react";
import { VITE_API_V2_URL } from "codes";
import { axiosBaseQuery } from "helpers/axiosbaseQuery";
import type { CreditLimitComment } from "./api-comments.d";

export const comments = createApi({
  reducerPath: "comments",
  tagTypes: ["CREDIT_LIMIT_COMMENTS"],
  baseQuery: axiosBaseQuery({
    baseUrl: `${VITE_API_V2_URL}/comments`,
  }),
  endpoints: (builder) => ({
    getCreditLimitComments: builder.query<CreditLimitComment[], string>({
      query: (clId) => ({
        url: `/credit_limit/${clId}`,
        method: "get",
      }),
      transformResponse: (response) => response.data,
      providesTags: ["CREDIT_LIMIT_COMMENTS"],
    }),
  }),
});

export const { useGetCreditLimitCommentsQuery } = comments;
