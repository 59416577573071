import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import { defaultValues } from "helpers/formFields";
import type { Step } from "types";
import type { RootState } from "../store";
import type { LeadCreationStep, StepState } from "./stepsReducer.d";

// Define a type for the slice state

// Define the initial state using that type
const initialState: StepState = {
  value: {
    form: defaultValues,
    step: 0,
  },

  lead: {
    form: defaultValues,
    step: 0,
  },
};

export const stepsSlice = createSlice({
  name: "steps",
  // `createSlice` will infer the state type from the `initialState` argument
  // Use the PayloadAction type to declare the contents of `action.payload`
  initialState,
  //CRUD
  reducers: {
    setSteps: (state, action: PayloadAction<Step>) => {
      state.value = action.payload;
    },

    setLeadCreationSteps: (state, action: PayloadAction<LeadCreationStep>) => {
      state.lead = action.payload;
    },
    clearSteps: () => initialState,
  },
});

export const { setSteps, clearSteps, setLeadCreationSteps } =
  stepsSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectSteps = (state: RootState) => state.steps.value;

export default stepsSlice.reducer;
