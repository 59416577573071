import { createApi } from "@reduxjs/toolkit/query/react";
import { VITE_API_V2_URL } from "codes";
import { axiosBaseQuery } from "helpers/axiosbaseQuery";
import type { GlobalConfig, RecursivePartial } from "types";

export const configuration = createApi({
  reducerPath: "configuration",
  tagTypes: [
    "config"
  ],
  baseQuery: axiosBaseQuery({
    baseUrl: VITE_API_V2_URL
  }),
  endpoints: (builder) => ({
    getConfiguration: builder.query<GlobalConfig, undefined>({
      query: (_) => ({
        url: '/configs',
        method: "get"
      }),
      providesTags: ["config"],
      transformResponse: (response) => response.data,
    }),
    updateConfiguration: builder.mutation<GlobalConfig, RecursivePartial<GlobalConfig>>({
      query: (data) => ({
        url: '/configs',
        method: "patch",
        data,
      }),
      invalidatesTags: ["config"],
      transformResponse: (response) => response.data,
    })
  }),
});

export const {
  useGetConfigurationQuery,
  useUpdateConfigurationMutation,
} = configuration;
