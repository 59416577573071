import { BUYER, PRODUCTS, SELLER, TERMSHEET_STATUS } from "codes";
import { PERMISSIONS } from "permissions";
import { useEffect, useState } from "react";
import type { Account, Termsheet } from "types";

const isTermsheetDisabled = (termsheet?: Termsheet, lead?: Account, canEdit: boolean = false) => {
  const status = termsheet?.termsheet_status
  return !canEdit
    || (termsheet !== undefined && status !== TERMSHEET_STATUS.IN_PROGRESS && status !== TERMSHEET_STATUS.REJECTED_INTERNAL)
    || (lead !== undefined && lead.account_product === PRODUCTS.factoring && lead.profile === BUYER?.toLowerCase())
    || (lead !== undefined && lead.account_product === PRODUCTS.reverse_factoring && lead.profile === SELLER?.toLowerCase())
}

const isSaveDisabled = (termsheet?: Termsheet, canEdit: boolean = false) => {
  return !canEdit
    || (
      termsheet?.termsheet_status !== undefined
      && termsheet?.termsheet_status !== TERMSHEET_STATUS.IN_PROGRESS
      && termsheet?.termsheet_status !== TERMSHEET_STATUS.REJECTED_INTERNAL
    )
}

const isSubmitForAprovalDisabled = (termsheet?: Termsheet, canEdit: boolean = false) => {
  return !canEdit || termsheet?.termsheet_status !== TERMSHEET_STATUS.IN_PROGRESS;
}

const isRecallDisabled = (termsheet?: Termsheet, canEdit: boolean = false) => {
  return !canEdit || termsheet?.termsheet_status !== TERMSHEET_STATUS.PENDING_INTERNAL;
}

const isReassignApproverDisabled = (termsheet?: Termsheet, canApprove: boolean = false) => {
  return !canApprove || termsheet?.termsheet_status !== TERMSHEET_STATUS.PENDING_INTERNAL;
}

const isInternalRejectDisabled = (termsheet?: Termsheet, canApprove: boolean = false) => {
  return !canApprove || termsheet?.termsheet_status !== TERMSHEET_STATUS.PENDING_INTERNAL;
}

const isInternalApproveDisabled = (termsheet?: Termsheet, canApprove: boolean = false) => {
  return !canApprove || termsheet?.termsheet_status !== TERMSHEET_STATUS.PENDING_INTERNAL;
}

const useTermsheetStatus = (termsheet?: Termsheet, lead?: Account, user_permissions: string[] = []) => {
  const canEdit = user_permissions.includes(PERMISSIONS.termsheet_editor)
  const canApprove = user_permissions.includes(PERMISSIONS.termsheet_approver)

  const [form, setTermsheetDisabled] = useState(isTermsheetDisabled(termsheet, lead, canEdit));
  const [save, setSave] = useState(isSaveDisabled(termsheet, canEdit));
  const [recall, setRecall] = useState(isRecallDisabled(termsheet, canEdit));
  const [submitForApproval, setSubmitForApproval] = useState(isSubmitForAprovalDisabled(termsheet, canEdit));
  const [reassignApprover, setReassignApprover] = useState(isReassignApproverDisabled(termsheet, canApprove));
  const [internalReject, setInternalReject] = useState(isInternalRejectDisabled(termsheet, canApprove));
  const [internalApprove, setInternalApprove] = useState(isInternalApproveDisabled(termsheet, canApprove));

  useEffect(() => {
    setTermsheetDisabled(isTermsheetDisabled(termsheet, lead, canEdit));
    setSave(isSaveDisabled(termsheet, canEdit));
    setRecall(isRecallDisabled(termsheet, canEdit));
    setSubmitForApproval(isSubmitForAprovalDisabled(termsheet, canEdit));
    setReassignApprover(isReassignApproverDisabled(termsheet, canApprove));
    setInternalReject(isInternalRejectDisabled(termsheet, canApprove));
    setInternalApprove(isInternalApproveDisabled(termsheet, canApprove));
  }, [termsheet]);

  return {
    action_disabled: {
      form,
      save,
      recall,
      submitForApproval,
      internalApprove,
      internalReject,
      reassignApprover
    }
  };
};

export default useTermsheetStatus;
