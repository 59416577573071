import { Container, Grid, Stack, Typography } from "@mui/material";
import { useGetOriginationTabQuery } from "api/api-maps";
import MainCard from "components/Common/Cards/MainCard";
import Layout from "components/Common/Layout";
import { currencyFormatter } from "helpers/currencyFormatter";
import moment from "moment";
import { MapContext } from "pages/maps/map";
import React from "react";

const TradePartnerPage: React.FC = () => {
  const mapName = React.useContext(MapContext);

  const { data: tabData, isLoading: isLoadingTabData } =
    useGetOriginationTabQuery(mapName ?? "", {
      skip: mapName === undefined,
    });

  return (
    <Container>
      <Stack justifyContent="start" alignItems="center" pt={2} spacing={2}>
        <Layout
          title="Trade Details"
          sx={{
            p: 0,
          }}
          layoutGridContainerProps={{
            spacing: 1,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={8}>
              {/* Left pane */}
              <Stack spacing={2} width="100%">
                <Stack>
                  <Typography color="error" fontWeight="bold">
                    Trade Comment
                  </Typography>
                  <MainCard
                    content
                    boxShadow
                    contentSX={{
                      bgcolor: "#a2a6c338",
                      px: 0.5,
                      py: 0.25,
                      ":last-child": {
                        p: 1,
                      },
                    }}
                  >
                    <Stack
                      dangerouslySetInnerHTML={{
                        __html: tabData?.trade_comments ?? "Not Specified",
                      }}
                      margin="auto"
                      sx={{
                        ".ql-align-justify": {
                          margin: 0,
                          marginBottom: 0.25,
                          fontSize: "0.5rem",
                        },
                      }}
                    />
                  </MainCard>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={4}>
              {/* Right pane */}
              <Stack
                spacing={1}
                width="100%"
                sx={{
                  bgcolor: "primary.main",
                  borderTopLeftRadius: 10,
                  borderBottomLeftRadius: 10,
                  height: "120%",
                  width: "110%",
                  pl: 2,
                  position: "relative",
                  zIndex: 3,
                }}
                id="customer-details-pane"
              >
                <Stack pt={3}>
                  <Typography
                    fontWeight="bold"
                    color="white.main"
                    fontSize={"0.6rem"}
                  >
                    Requested Amount
                  </Typography>
                  <Typography color="white.main" fontSize={"0.5rem"}>
                    {tabData?.requested_facility
                      ? currencyFormatter({
                          amount: tabData?.requested_facility,
                          currency: "USD",
                        })
                      : "Not Specified"}
                  </Typography>
                </Stack>
                <Stack>
                  <Typography
                    fontWeight="bold"
                    color="white.main"
                    fontSize={"0.6rem"}
                  >
                    First Invoice Expected Amount
                  </Typography>
                  <Typography color="white.main" fontSize={"0.5rem"}>
                    {tabData?.first_invoice_expected_amount
                      ? currencyFormatter({
                          amount: tabData?.first_invoice_expected_amount,
                          currency: "USD",
                        })
                      : "Not Specified"}
                  </Typography>
                </Stack>
                <Stack>
                  <Typography
                    fontWeight="bold"
                    color="white.main"
                    fontSize={"0.6rem"}
                  >
                    First Invoice Expected Date
                  </Typography>
                  <Typography color="white.main" fontSize={"0.5rem"}>
                    {tabData?.first_invoice_expected_date
                      ? moment(tabData?.first_invoice_expected_date).format(
                          "DD/MM/YYYY"
                        )
                      : "Not Specified"}
                  </Typography>
                </Stack>
                <Stack>
                  <Typography
                    fontWeight="bold"
                    color="white.main"
                    fontSize={"0.6rem"}
                  >
                    Tenor
                  </Typography>
                  <Typography color="white.main" fontSize={"0.5rem"}>
                    {tabData?.tenor ?? "Not Specified"}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Layout>
      </Stack>
    </Container>
  );
};

export default TradePartnerPage;
