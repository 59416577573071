import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const svgToRaster = async (
  svgElement: SVGSVGElement
): Promise<HTMLImageElement> => {
  const svgData = new XMLSerializer().serializeToString(svgElement);
  const svgBlob = new Blob([svgData], { type: "image/svg+xml;charset=utf-8" });
  const url = URL.createObjectURL(svgBlob);

  const img = new Image();
  img.src = url;

  // Wait for the image to load
  await new Promise((resolve, reject) => {
    img.onload = resolve;
    img.onerror = reject;
  });

  URL.revokeObjectURL(url); // Clean up the URL
  return img;
};

export const downloadStacksAsPdf = async ({
  stackIds,
  fileName,
  orientation = "landscape",
}: {
  stackIds: string[]; // Array of IDs for each stack
  fileName: string;
  orientation?: "portrait" | "landscape";
}) => {
  const pdf = new jsPDF({
    orientation,
    unit: "pt",
    format: "a4",
  });

  const allSvgPromises = stackIds.map(async (id) => {
    const stackDiv = document.getElementById(id);
    if (!stackDiv) {
      console.warn(`Element with id '${id}' not found.`);
      return;
    }

    const svgs = stackDiv.querySelectorAll("svg");
    const svgPromises = Array.from(svgs).map(async (svg) => {
      const img = await svgToRaster(svg as SVGSVGElement);
      img.width = svg.clientWidth;
      img.height = svg.clientHeight;
      svg.replaceWith(img);
    });
    await Promise.all(svgPromises);

    return stackDiv;
  });

  const stackDivs = await Promise.all(allSvgPromises);

  for (const [index, stackDiv] of stackDivs.entries()) {
    //eslint-disable-next-line no-continue
    if (!stackDiv) continue;

    //eslint-disable-next-line no-await-in-loop
    const canvas = await html2canvas(stackDiv, {
      scale: 5, // High resolution for better quality
      useCORS: true, // Ensure cross-origin SVGs render correctly
      removeContainer: true, // Remove temporary DOM elements added by html2canvas
    });
    const imgData = canvas.toDataURL("image/jpeg", 1);

    const imgWidth = 841.89; // A4 width in pt (landscape)
    const imgHeight = (canvas.height * imgWidth) / canvas.width; // Maintain aspect ratio

    pdf.addImage(imgData, "JPEG", 0, 0, imgWidth, imgHeight);

    // Add a new page if this isn't the last stack
    if (index < stackIds.length - 1) {
      pdf.addPage();
    }
  }

  pdf.save(fileName);
};
