import { Grid, Stack } from "@mui/material";
import { useGetCurrentUserQuery } from "api/api-users-v2";
import Layout from "components/Common/Layout";
import HealthCheck from "components/HealthCheck";
import React, { type ComponentType } from "react";
import { useAppSelector } from "redux/hooks";

const InternalDashboardHOC = <P extends object>(
  WrappedComponent: ComponentType<P>
) => {
  const HOC: React.FC<P> = (props) => {
    const { data: user, isFetching: isFetchingUsers } =
      useGetCurrentUserQuery();
    const USER_PERMISSIONS = useAppSelector(
      (state) => state.appState.user_permission
    );
    const IS_DASHBOARD_ADMIN = USER_PERMISSIONS.includes("dashboard_admin");

    return (
      <Layout
        title={`Welcome, ${user?.first_name ?? ""} ${user?.last_name ?? ""}`}
        id="internal-dashboard"
      >
        <Stack spacing={4}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={IS_DASHBOARD_ADMIN ? 9 : 12}>
              <WrappedComponent {...(props as P)} />
            </Grid>
            {IS_DASHBOARD_ADMIN && (
              <Grid item xs={12} lg={3}>
                <HealthCheck />
              </Grid>
            )}
          </Grid>
        </Stack>
      </Layout>
    );
  };

  return HOC;
};

export default InternalDashboardHOC;
