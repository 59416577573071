import { Grid, Stack, Typography, useTheme, type ButtonOwnProps } from "@mui/material";
import Input from "components/Common/Input";

import Modal from "components/Common/Modal";

import { useGetApproversListQuery } from "api/api-accounts";
import Drop from "components/Common/Drop";
import SkeletonLoad from "components/Common/SkeletonLoad";
import { Form, Formik, type FormikProps } from "formik";
import useIsMobile from "hooks/useIsMobile";
import React, { useEffect } from "react";
import { toast } from "react-toastify";
import secondaryButtonStyle from "styles/secondaryButtonStyle";
import * as Yup from 'yup';

interface ModalProps {
  setOpen: (index: boolean) => void;
  open: boolean;
  title: string;
  primary?: {
    children?: React.ReactNode;
    loading?: boolean;
    color?: ButtonOwnProps['color'];
    onClick?: (args: { assignee_id?: string, comments?: string }) => void;
  };
  drop?: {
    label: string;
  }
}

interface TermsheetSubmitFormProps {
  assignee?: {
    label: string;
  }
  formik: FormikProps<{
    assignee_name: string;
    assignee_id: string;
    comments: string;
  }>
}

const TermsheetActionForm = ({
  assignee,
  formik
}: TermsheetSubmitFormProps) => {
  const { data, isFetching } = useGetApproversListQuery(null)
  const options = data?.map((item) => ({ value: item.contact_id, name: `${item.first_name} ${item.last_name}` }))

  const setAssignee = (value: string) => {
    formik.setFieldValue("assignee_id", options?.find((item) => item.name === value)?.value, true)
    formik.setFieldValue("assignee_name", value, true)
    formik.setTouched({ assignee_id: true, assignee_name: true }, true)
  }

  useEffect(() => {
    if (!data?.length) return;
    const assignee = data.find((item) => item.first_name.toLowerCase() === "loic");
    if (assignee) {
      setAssignee(`${assignee.first_name} ${assignee.last_name}`);
    }
  }, [data])

  return <Grid item xs={12}>
    <Stack spacing={1} direction={"column"}>
      {assignee && (
        isFetching || options === undefined
          ? <SkeletonLoad bars={1} />
          : <Drop
            label={assignee.label}
            multilevel={false}
            data={options}
            name="assignee_name"
            keyValue="name"
            direction={"row"}
            required
            setValue={setAssignee}
            {...formik}
          />
      )}
      <Input
        placeholder="Comments..."
        name="comments"
        label=""
        multiline
        fullWidth
        {...formik}
      />
    </Stack>
  </Grid>
}

const TermsheetModal = ({
  setOpen,
  open,
  title,
  drop,
  primary = { children: "Confirm", loading: false },
}: ModalProps) => {
  const theme = useTheme();
  const isMobile = useIsMobile();
  primary = { children: "Confirm", loading: false, color: "primary", ...primary }

  const initialValues = {
    assignee_id: "",
    assignee_name: "",
    comments: "",
  };

  const modalSx = {
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  };
  const modalOverlaySx = {
    backgroundColor: `${theme.palette.background.default} !important`,
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "2ch",
    padding: "2ch",
    fontSize: `${theme.typography.fontSize}px !important`,
    fontFamily: `${theme.typography.fontFamily} !important`,
    fontWeight: "inherit !important",
  };

  const validationSchema = Yup.object().shape({
    assignee_name: drop
      ? Yup.string().required("Approver is required")
      : Yup.string().nullable(),
    comments: Yup.string().required("Comments is required"),
  })

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={console.log}
      validationSchema={validationSchema}
      validateOnMount
      enableReinitialize
    >
      {(formikProps) => (
        <Form>
          <Modal
            sectionDivider
            height={drop ? 180 : 140}
            Header={
              <Typography fontWeight="bold" fontSize="1.3em">
                {title}
              </Typography>
            }
            message={
              <TermsheetActionForm formik={formikProps} assignee={drop} />
            }
            modalHeaderSx={{ p: 2, backgroundColor: `${theme.palette.background.default}` }}
            sx={modalSx}
            modalOverlaySx={modalOverlaySx}
            modalFooterSx={{ backgroundColor: `${theme.palette.background.default}` }}
            messageContainerProps={{
              sx: {
                justifyContent: "center",
                alignItems: "center"
              }
            }}
            width={isMobile ? "90%" : "25%"}
            open={open}
            onClose={() => setOpen(false)}
            primary={{
              children: primary.children,
              loading: primary.loading,
              color: primary.color,
              onClick: () => {
                if (Object.keys(formikProps.errors).length) {
                  let message: string = "";
                  Object.values(formikProps.errors)
                    .forEach((error) => message += `${error}, `);
                  toast.error(message);
                  return
                }
                const { assignee_id, comments } = formikProps.values;
                if (primary.onClick) {
                  primary?.onClick({ assignee_id, comments })
                }
                setOpen(false)
                // validations does not seem to work with resetForm
                // https://github.com/jaredpalmer/formik/issues/3512#issuecomment-1097870377
                // formikProps.resetForm({ values: initialValues})
                formikProps.setValues(initialValues, true)
              },
            }}
            secondary={{
              children: "Cancel",
              color: 'secondary',
              onClick: () => {
                setOpen(false)
                // formikProps.resetForm({ values: initialValues})
                formikProps.setValues(initialValues, true)
              },
              sx: secondaryButtonStyle,
              variant: "outlined",
            }}
            closeButton={false}
          />
        </Form>
      )}
    </Formik>
  );
};

export default TermsheetModal;
