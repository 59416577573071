import { createApi } from "@reduxjs/toolkit/query/react";
import { VITE_API_V2_URL } from "codes";
import { axiosBaseQuery } from "helpers/axiosbaseQuery";

export const guideline = createApi({
  reducerPath: "guideline",
  tagTypes: [
    "GUIDELINES",
  ],
  baseQuery: axiosBaseQuery({
    baseUrl: `${VITE_API_V2_URL}/guides`,
  }),
  endpoints: (builder) => ({
    getGuidelines: builder.query<
      {id: string, title: string, link: string}[],
      null
    >({
      query: () => ({
        url: "",
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["GUIDELINES"],
    }),
    addGuideline: builder.mutation<
      {id: string, title: string, link: string}[],
      {title: string, link: string}
    >({
      query: (data) => ({
        url: "",
        method: "POST",
        data: data,
      }),
      transformResponse: (response) => response?.data,
      invalidatesTags: ["GUIDELINES"],
    }),
  }),
});

export const {
    useGetGuidelinesQuery,
    useAddGuidelineMutation,
} = guideline;
