import { Container, Grid, Stack, Typography } from "@mui/material";
import {
  useGetCompanyLogoQuery,
  useGetOriginationTabQuery,
} from "api/api-maps";
import placeholder from "assets/placeholder-company.png";
import MainCard from "components/Common/Cards/MainCard";
import ImageUpload from "components/Common/ImageUpload";
import Layout from "components/Common/Layout";
import { currencyFormatter } from "helpers/currencyFormatter";
import moment from "moment";
import { MapContext } from "pages/maps/map";
import React from "react";

const TradePartnerPage: React.FC = () => {
  const mapName = React.useContext(MapContext);

  const { data: tabData, isLoading: isLoadingTabData } =
    useGetOriginationTabQuery(mapName ?? "", {
      skip: mapName === undefined,
    });

  const {
    data: companyLogo = {
      url: placeholder,
    },
  } = useGetCompanyLogoQuery(
    {
      map_name: mapName ?? "",
      account_type: tabData?.trade_partner?.account_type ?? "",
    },
    {
      skip: !mapName || !tabData?.trade_partner?.account_type,
      refetchOnFocus: true,
      refetchOnReconnect: true,
      refetchOnMountOrArgChange: true,
    }
  );

  return (
    <Container>
      <Stack justifyContent="start" alignItems="center" pt={2} spacing={2}>
        <Layout
          title="Trade Partner"
          sx={{
            p: 0,
          }}
          layoutGridContainerProps={{
            spacing: 1,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={8}>
              {/* Left pane */}
              <Stack spacing={2} width="100%">
                <Stack>
                  <Typography color="error" fontWeight="bold">
                    Comment of Customer
                  </Typography>
                  <MainCard
                    content
                    boxShadow
                    contentSX={{
                      bgcolor: "#a2a6c338",
                      px: 0.5,
                      py: 0.25,
                      ":last-child": {
                        p: 1,
                      },
                    }}
                  >
                    <Stack
                      dangerouslySetInnerHTML={{
                        __html:
                          tabData?.trade_partner?.trade_partner_comments ??
                          "Not Specified",
                      }}
                      margin="auto"
                      sx={{
                        ".ql-align-justify": {
                          margin: 0,
                          marginBottom: 0.25,
                          fontSize: "0.5rem",
                        },
                      }}
                    />
                  </MainCard>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={4}>
              {/* Right pane */}
              <Stack
                spacing={1}
                width="100%"
                sx={{
                  bgcolor: "primary.main",
                  borderTopLeftRadius: 10,
                  borderBottomLeftRadius: 10,
                  height: "120%",
                  width: "110%",
                  pl: 2,
                  position: "relative",
                  zIndex: 3,
                }}
                id="customer-details-pane"
              >
                <ImageUpload
                  variant="rounded"
                  src={companyLogo?.url}
                  sx={{
                    width: "4rem",
                    height: "4rem",
                    borderRadius: 1,
                    alignItems: "start",
                  }}
                  viewOnly
                />
                <Stack>
                  <Typography
                    fontWeight="bold"
                    color="white.main"
                    fontSize={"0.6rem"}
                  >
                    Name
                  </Typography>
                  <Typography color="white.main" fontSize={"0.5rem"}>
                    {tabData?.trade_partner?.name ?? "Not Specified"}
                  </Typography>
                </Stack>
                <Stack>
                  <Typography
                    fontWeight="bold"
                    color="white.main"
                    fontSize={"0.6rem"}
                  >
                    Country
                  </Typography>
                  <Typography color="white.main" fontSize={"0.5rem"}>
                    {tabData?.trade_partner?.country ?? "Not Specified"}
                  </Typography>
                </Stack>
                <Stack>
                  <Typography
                    fontWeight="bold"
                    color="white.main"
                    fontSize={"0.6rem"}
                  >
                    Turnover
                  </Typography>
                  <Typography color="white.main" fontSize={"0.5rem"}>
                    {tabData?.trade_partner?.turnover
                      ? currencyFormatter({
                          amount: tabData?.trade_partner?.turnover,
                          currency: "USD",
                        })
                      : "Not Specified"}
                  </Typography>
                </Stack>
                <Stack>
                  <Typography
                    fontWeight="bold"
                    color="white.main"
                    fontSize={"0.6rem"}
                  >
                    Incorporation Date
                  </Typography>
                  <Typography color="white.main" fontSize={"0.5rem"}>
                    {tabData?.trade_partner?.incorporation_date
                      ? moment(
                          tabData?.trade_partner?.incorporation_date
                        ).format("DD/MM/YYYY")
                      : "Not Specified"}
                  </Typography>
                </Stack>
                <Stack>
                  <Typography
                    fontWeight="bold"
                    color="white.main"
                    fontSize={"0.6rem"}
                  >
                    Website
                  </Typography>
                  <Typography
                    color="white.main"
                    fontSize={"0.5rem"}
                    sx={{
                      wordBreak: "break-all",
                    }}
                  >
                    {tabData?.trade_partner?.website ?? "Not Specified"}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Layout>
      </Stack>
    </Container>
  );
};

export default TradePartnerPage;
