import { ONBOARDED, ONBOARDING } from "codes";
import menu from "components/Navigation/menu";
import {
  VITE_EXPERIMENTAL_EWALLET,
  VITE_INVOICES_PAGE,
} from "experimental_features";
import { t } from "i18next";
import { useAppSelector } from "redux/hooks";
import NavGroup from "./NavGroup";

// ==============================|| SIDEBAR MENU LIST ||============================== //

export interface Item {
  title: string;
  icon: any;
  activeColor: string;
  iconActiveColor: string;
  iconInactiveColor: string;
  circularBackground: boolean;
  link: string;
  match?: (x: string) => boolean;
  items?: Item[];
  hidden?: boolean;
  onClick?: (x?: any) => any;
}

const MenuList = () => {
  const APP_STATE = useAppSelector((state) => state.appState.value);
  const POST_SALES = [ONBOARDING, ONBOARDED];
  const menuItems: Item[] = menu()
    .map((item) => {
      if (item.title === "Users")
        return POST_SALES.includes(APP_STATE) ? item : null;
      if (item.title === t("invoices")) return VITE_INVOICES_PAGE ? item : null;
      if (item.title === "E-wallet")
        return VITE_EXPERIMENTAL_EWALLET ? item : null;
      return item;
    })
    .filter((x) => x !== null) as Item[];

  return <NavGroup items={menuItems} />;
};

export default MenuList;
