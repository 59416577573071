import { createApi } from "@reduxjs/toolkit/query/react";
import { VITE_API_V2_URL } from "codes";
import { axiosBaseQuery } from "helpers/axiosbaseQuery";
import type { INotification } from "./api-notifications.d";

export const notifications = createApi({
  reducerPath: "notifications",
  tagTypes: ["NOTIFICATIONS"],

  baseQuery: axiosBaseQuery({
    baseUrl: `${VITE_API_V2_URL}/notifications`,
  }),
  endpoints: (builder) => ({
    getNotifications: builder.query<INotification[], void>({
      query: () => ({
        url: ``,
        method: "get",
      }),
      transformResponse: (response: any) => response.data,
      providesTags: ["NOTIFICATIONS"],
    }),
    updateMarkAsRead: builder.mutation<INotification, string>({
      query: (id) => ({
        url: `/${id}`,
        method: "put",
      }),
      invalidatesTags: ["NOTIFICATIONS"],
    }),
    updateMarkAsReadAll: builder.mutation<INotification[], void>({
      query: () => ({
        url: `/read-all`,
        method: "put",
      }),
      invalidatesTags: ["NOTIFICATIONS"],
    }),
  }),
});

export const {
  useGetNotificationsQuery,
  useUpdateMarkAsReadMutation,
  useUpdateMarkAsReadAllMutation,
} = notifications;
