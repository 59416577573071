import { Avatar, Stack, Typography, useTheme } from "@mui/material";
import placeholder from "assets/thankyou.jpg";
import React from "react";

interface ThankyouPageProps {}
const ThankyouPage: React.FC<ThankyouPageProps> = () => {
  const theme = useTheme();
  return (
    <Stack
      height="100%"
      justifyContent="center"
      alignItems="center"
      width="100%"
      position="relative"
      id="thank-you-page"
      sx={{
        position: "relative",
        zIndex: 3,
      }}
    >
      <Stack
        direction="row"
        spacing={2}
        maxWidth="100%"
        alignItems="center"
        width="100%"
        ml="-9%"
      >
        <Avatar
          src={placeholder}
          sx={{
            width: "30vh",
            height: "30vh",
            clipPath: "polygon(10% -20%, 100% 0%, 120% 100%, 10% 120%)",
            boxShadow: "3px 1px 10px 15px #00000078",
            outline: `10px solid ${theme.palette.white.main}`,
          }}
        />
        <Stack spacing={2} pl={2}>
          <Typography variant="h2" fontWeight="bolder" color="white.main">
            THANK YOU!
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ThankyouPage;
