import { Box, Divider, Grid, Link, Typography } from "@mui/material";
import { useGetCurrentUserQuery } from "api/api-users-v2";
import { SUPPORT_EMAIL } from "codes";
import ErrorPage from "components/Common/ErrorPage";
import history from "helpers/history";
import moment from "moment";
import { version } from "../../../package.json";

const Error = ({
  error,
  componentStack,
  resetError,
  scope,
}: {
  error: Error;
  resetError: () => void;
  componentStack: string | null;
  scope: string;
}) => {
  const { push, back, go } = history;

  const time = moment().format();
  const { data: curr_user, isFetching } = useGetCurrentUserQuery();
  const errorObject: { message: string; name: string; stack: string } =
    error.cause as any;
  const env = import.meta.env.VITE_ENV;
  if (
    errorObject.message ===
    "Rendered more hooks than during the previous render."
  )
    go(0);
  const sentence = error.stack;
  const regex = /(https?:\/\/[^\s)]+)/;
  const match = sentence?.match(regex);
  const link = match ? match[0] : "";
  return (
    <Box>
      <ErrorPage
        goBack={() => {
          back();
          resetError();
        }}
        push={push}
        address={SUPPORT_EMAIL}
      />
      {env !== "main" && (
        <Grid container maxWidth="75vw" margin="auto" spacing={3}>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                {`[${time}] ${errorObject.message} method called by `}
                <strong>{`${curr_user?.first_name} ${curr_user?.last_name} `}</strong>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                {`[${time}] `}
                <strong>Configurations:</strong>
              </Grid>
              <Grid item xs={12}>
                {`At `}{" "}
                <Typography
                  sx={{
                    backgroundColor: "rgba(222, 222, 222, 0.3)",
                  }}
                  color="markdown.main"
                  fontFamily="monospace, monospace"
                >
                  {scope}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {`Version `}
                <strong>{`${version}`}</strong>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                {`[${time}] `}
                <strong>Process Logs:</strong>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <strong>ENV:</strong>
              <Typography
                sx={{
                  backgroundColor: "rgba(222, 222, 222, 0.3)",
                }}
                color="markdown.main"
                fontFamily="monospace, monospace"
              >
                {env}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <strong>Component stack: </strong>
            </Grid>
            <Grid item xs={12}>
              <div style={{ overflowX: "auto" }}>
                <Typography
                  sx={{
                    backgroundColor: "rgba(222, 222, 222, 0.3)",
                  }}
                  color="markdown.main"
                  fontFamily="monospace, monospace"
                >
                  {componentStack}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div style={{ overflowX: "auto" }}>
              <table data-number-column="false">
                <tbody>
                  <tr>
                    <td
                      rowSpan={1}
                      colSpan={2}
                      data-cell-background="#ffe6e6"
                      style={{ backgroundColor: "rgb(255, 230, 230)" }}
                    >
                      <p data-renderer-start-pos="284">
                        <strong data-renderer-mark="true">
                          An error occurred
                        </strong>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td rowSpan={1} colSpan={1} aria-label="Timestamp">
                      <p data-renderer-start-pos="307">
                        <strong data-renderer-mark="true">Timestamp:</strong>
                      </p>
                    </td>
                    <td rowSpan={1} colSpan={1}>
                      <p data-renderer-start-pos="321">{time}</p>
                    </td>
                  </tr>
                  <tr>
                    <td rowSpan={1} colSpan={1}>
                      <p data-renderer-start-pos="327">
                        <strong data-renderer-mark="true">Type:</strong>
                      </p>
                    </td>
                    <td rowSpan={1} colSpan={1}>
                      <p data-renderer-start-pos="336">{errorObject.name}</p>
                    </td>
                  </tr>
                  <tr>
                    <td rowSpan={1} colSpan={1}>
                      <p data-renderer-start-pos="342">
                        <strong data-renderer-mark="true">Message:</strong>
                      </p>
                    </td>
                    <td rowSpan={1} colSpan={1}>
                      <p data-renderer-start-pos="354">{errorObject.message}</p>
                    </td>
                  </tr>
                  <tr>
                    <td rowSpan={1} colSpan={1}>
                      <p data-renderer-start-pos="360">
                        <strong data-renderer-mark="true">Line Number:</strong>
                      </p>
                    </td>
                    <td rowSpan={1} colSpan={1} aria-label="Line Number">
                      <p data-renderer-start-pos="376">&nbsp;</p>
                    </td>
                  </tr>
                  <tr>
                    <td rowSpan={1} colSpan={1}>
                      <p data-renderer-start-pos="382">
                        <strong data-renderer-mark="true">Stack Trace:</strong>
                      </p>
                    </td>
                    <td rowSpan={1} colSpan={1}>
                      <Link
                        sx={{
                          backgroundColor: "rgba(222, 222, 222, 0.3)",
                        }}
                        color="markdown.main"
                        fontFamily="monospace, monospace"
                        href={link}
                        target="_blank"
                      >
                        {error.stack}
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td rowSpan={1} colSpan={1}>
                      <p data-renderer-start-pos="404">
                        <strong data-renderer-mark="true">
                          Inner Exception:
                        </strong>
                      </p>
                    </td>
                    <td rowSpan={1} colSpan={1} aria-label="Inner Exception">
                      <p data-renderer-start-pos="424">&nbsp;</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};
export default Error;
