import LoadingButton from "@mui/lab/LoadingButton";
import { Grid, InputAdornment, Stack, styled, Switch, Typography } from "@mui/material";
import { useGetConfigurationQuery, useUpdateConfigurationMutation } from "api/api-configuration";
import MainCard from "components/Common/Cards/MainCard";
import Input from "components/Common/Input";
import Layout from "components/Common/Layout";
import SkeletonLoad from "components/Common/SkeletonLoad";
import { Form, Formik } from "formik";
import React from "react";
import { isMobile } from "react-device-detect";
import { toast } from "react-toastify";
import type { GlobalConfig, RecursivePartial } from "types";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#1890ff",
        ...theme.applyStyles("dark", {
          backgroundColor: "#177ddc",
        }),
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: "rgba(0,0,0,.25)",
    boxSizing: "border-box",
    ...theme.applyStyles("dark", {
      backgroundColor: "rgba(255,255,255,.35)",
    }),
  },
}));

const ConfigurationPage: React.FC = () => {
  const OnOffSwitch = () => (
    <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
      <Typography>Off</Typography>
      <AntSwitch defaultChecked inputProps={{ "aria-label": "ant design" }} />
      <Typography>On</Typography>
    </Stack>
  );

  const { data: config, isFetching } = useGetConfigurationQuery(undefined);
  const [updateConfig, { isLoading }] = useUpdateConfigurationMutation();

  const initialValues: RecursivePartial<GlobalConfig> = {
    ...config
  }

  return isFetching
    ? (<SkeletonLoad bars={10} />)
    : (<Layout
      title="Configuration"
      textHeadingOptions={{
        fontWeight: "bolder",
        level: 2,
        fontSize: isMobile ? "1.2em" : "1.5em",
      }}
    >
      {/* Term sheet config */}
      <Formik
        initialValues={initialValues['TERMSHEET']}
        onSubmit={console.log}
      >
        {(formik) => (
          <MainCard
            title="Term Sheet"
            headerSx={{ px: 2, py: 1, }}
            footer={
              <Grid
                item
                xs={12}
                lg={2}
                ml={"auto"}
                justifyContent="flex-end"
              >
                <LoadingButton
                  variant="contained"
                  fullWidth
                  data-testid="save-termsheet"
                  loading={isLoading}
                  onClick={() => {
                    updateConfig({ TERMSHEET: { ...formik.values } })
                      .unwrap()
                      .then(() => toast.success("Updated settings"))
                      .catch((err) => toast.error(err.message))
                  }}
                  disabled={!formik.dirty}
                >save</LoadingButton>
              </Grid>
            }
          >
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Typography>Term Sheet approval countdown</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack spacing={2} direction="row" alignItems="center">
                    <Input
                      label={undefined}
                      type="number"
                      {...formik.getFieldProps("termsheet_expiry_days")}
                      sx={{ maxWidth: "150px", }}
                      textfieldProps={{
                        InputProps: {
                          endAdornment: (<InputAdornment position="start">days</InputAdornment>)
                        }
                      }}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography>Onboarding Submission countdown</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack spacing={2} direction="row" alignItems="center">
                    <Input
                      label={undefined}
                      type="number"
                      {...formik.getFieldProps(
                        "invoice_expiry_days"
                      )}
                      sx={{ maxWidth: "150px", }}
                      textfieldProps={{
                        InputProps: {
                          endAdornment: (<InputAdornment position="start">days</InputAdornment>)
                        }
                      }}
                    />
                  </Stack>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Typography>Annualized Discount Rate</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack spacing={2} direction="row" alignItems="center">
                    <Input
                      label={undefined}
                      type="number"
                      {...formik.getFieldProps(
                        "annualized_discount_rate"
                      )}
                      sx={{ maxWidth: "150px" }}
                      textfieldProps={{
                        InputProps: {
                          endAdornment: (<InputAdornment position="start">%</InputAdornment>)
                        }
                      }}
                    />
                  </Stack>
                </Grid>
              </Grid>
            </Form>
          </MainCard>
        )}
      </Formik>
    </Layout>
    );
};

export default ConfigurationPage;
