import LoadingButton from "@mui/lab/LoadingButton";
import { CL_DEFAULT, SUPPLIER } from "codes";
import { FormikProps, useFormikContext } from "formik";
import { RegistrationFormFields } from "helpers/formFields";
import { AddPartnerType } from "helpers/formFields/tradingRelationship";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import type { Partner } from "types";
import {
  hasSameRegNo,
  hasSimilarName,
  hasSimilarRegNo,
  nameHasSimilarRegNo,
} from "../processor";

interface IAddPartnerSubmission {
  company_iso3: string;
  registrationNumber: string;
  name: string;

  companyType: string;
  buyerAdditionalDetailsData: any[];
  setBuyerSubmittedIdenfy: (value: boolean) => void;
  setBuyerDetailsSubmitted: (value: boolean) => void;
  setIsVendorAvailable: (value: boolean) => void;
  setFoundCompanies: (value: any[]) => void;
  setOpen: Dispatch<SetStateAction<boolean[]>>;
  parentFP: FormikProps<RegistrationFormFields>;
}
const AddPartnerSubmission = ({
  company_iso3,
  registrationNumber,
  name,
  companyType,
  buyerAdditionalDetailsData,
  setBuyerSubmittedIdenfy,
  setBuyerDetailsSubmitted,
  setIsVendorAvailable,
  setFoundCompanies,
  setOpen,
  parentFP,
}: IAddPartnerSubmission) => {
  const formikProps = useFormikContext<AddPartnerType>();
  const { t } = useTranslation();
  const { list } = parentFP.values;

  const addBuyerToList = () => {
    const buyerData: Partner = {
      registration_number: formikProps.values.registration_number,
      name: formikProps.values.name,
      country: formikProps.values.country,
      iso3: company_iso3,
      is_identified: false,
      showDelete: true,
    };
    if (companyType?.toLowerCase() === SUPPLIER.toLowerCase())
      buyerData["requested_amount"] = CL_DEFAULT;
    const combinedData = list.map((item) => {
      const additionalDetails = buyerAdditionalDetailsData.find(
        (details) => details.registration_number === item.registration_number
      );
      return additionalDetails ? { ...item, ...additionalDetails } : item;
    });
    const dataToSet = [...combinedData, buyerData];

    formikProps.resetForm();
    const newList = dataToSet.filter((item) => item.showDelete);
    parentFP?.setFieldValue("list", newList, true);

    setBuyerSubmittedIdenfy(true);
    setBuyerDetailsSubmitted(false);
  };

  return (
    <LoadingButton
      variant="contained"
      fullWidth
      loading={false}
      onClick={() => {
        setBuyerSubmittedIdenfy(false);
        //check with existing partners and staged partners
        if (Boolean(hasSimilarRegNo(list, formikProps))) {
          if (
            Boolean(
              (nameHasSimilarRegNo(list, formikProps) ||
                hasSimilarName(list, formikProps)) &&
                hasSameRegNo(list, formikProps)
            )
          )
            toast(`${t("please-select-another-buyer")}`, {
              type: "error",
            });
          else if (Boolean(hasSameRegNo(list, formikProps)))
            toast(`${t("please-select-another-buyer")}`, {
              type: "error",
            });
          else addBuyerToList();
        }
        //check with own company data
        else if (
          registrationNumber
            .toLowerCase()
            .includes(
              `${formikProps.values.registration_number}`.toLowerCase().trim()
            )
        ) {
          if (
            (name.toLowerCase() ===
              `${formikProps.values.name}`.toLowerCase().trim() ||
              registrationNumber
                .toLowerCase()
                .includes(`${formikProps.values.name}`.toLowerCase().trim())) &&
            registrationNumber.toLowerCase() ===
              `${formikProps.values.registration_number}`.toLowerCase().trim()
          )
            toast(`${t("please-select-another-company")}`, {
              type: "error",
            });
          else if (
            registrationNumber.toLowerCase() ===
            `${formikProps.values.registration_number}`.toLowerCase().trim()
          )
            toast(`${t("please-select-another-company")}`, {
              type: "error",
            });
          else addBuyerToList();
        } else addBuyerToList();
        setOpen((prevOpen) => [...prevOpen, true]);
      }}
      disabled={!formikProps.values.country || !formikProps.values.name}
    >
      {`${t("submit")}`}
    </LoadingButton>
  );
};
export default AddPartnerSubmission;
