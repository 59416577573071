import InfoIcon from "@mui/icons-material/Info";
import { InputLabel, Stack, Tooltip, Typography } from "@mui/material";

const Label: React.FC<{
  label?: string | React.ReactNode;
  required?: boolean;
  helpText?: string;
}> = ({ label, required, helpText }) => {
  return (
    <Stack
      alignItems="center"
      justifyContent="start"
      spacing={0.5}
      direction="row"
      mb={0.5}
    >
      {label && typeof label === "string" && (
        <Typography fontWeight="bold">{label && `${label}`}</Typography>
      )}
      {label && typeof label === "object" && label}
      {required && <Typography color="error">*</Typography>}
      {Boolean(helpText) && (
        <Tooltip title={helpText} enterTouchDelay={0} leaveDelay={200}>
          <InfoIcon color="primary" sx={{ marginTop: "6px" }} />
        </Tooltip>
      )}
      <InputLabel />
    </Stack>
  );
};

export default Label;
