import {
  Grid,
  Stack,
  Typography,
  useColorScheme,
  useTheme
} from "@mui/material";
import LogoDesktopDark from "assets/logo-dark.svg?react";
import LogoDesktop from "assets/logo.svg?react";
import { FINANCE_TYPE, PRODUCTS } from "codes";
import MainCard from "components/Common/Cards/MainCard";
import moment from "moment";
import type { Account, Termsheet } from "types";

interface TermSheetPreviewProps {
  termsheet?: Partial<Termsheet>;
  lead?: Account;
  partner: Account;
  creditLimit?: number;
}

const getTermsheetDate = (termsheet?: Partial<Termsheet>) => {
  if (!termsheet) {
    return undefined;
  }
  const date = termsheet?.termsheet_signed_date;
  if (!date) {
    return undefined;
  }
  return moment(date).format("DD-MMMM-YYYY")
}

const TermSheetPreview = (props: TermSheetPreviewProps) => {
  const theme = useTheme()
  const { mode } = useColorScheme()
  const { termsheet, partner, lead, creditLimit } = props

  const termsheetDate = getTermsheetDate(termsheet)
  const product = termsheet?.lead?.account_product ?? partner?.account_product;
  const values = {
    product,
    advancedAmount: termsheet?.advanced_amount ?? partner?.advanced_amount,
    financingType: product === PRODUCTS.factoring
      ? FINANCE_TYPE.receivable_financing
      : FINANCE_TYPE.payable_financing,
    customerName: termsheet?.lead?.name ?? lead?.name,

    clientDetails: {
      companyName: termsheet?.lead?.name ?? lead?.name,
      country: termsheet?.lead?.country ?? lead?.country,
      tradeGoodsDescription: termsheet?.trade_goods_description,
    },

    costs: {
      preQualifiedPartner: partner?.name,
      creditLimit: termsheet?.credit_limit?.approved_amount ?? creditLimit,
      currency: termsheet?.currency,
      annualizedDiscountRate: termsheet?.annualized_discount_rate ?? lead?.annualized_discount_rate,
      expected_tenure: termsheet?.average_funding_period,
      facility_fee: termsheet?.facility_fee ?? lead?.facility_fee,
      setup_fee: termsheet?.setup_fee ?? lead?.setup_fee,
      customer_maintenance_fee: termsheet?.customer_maintenance_fee ?? lead?.customer_maintenance_fee,
      partner_maintenance_fee: termsheet?.partner_maintenance_fee ?? lead?.partner_maintenance_fee,
    }
  }

  const Logo = mode === "light" ? LogoDesktop : LogoDesktopDark
  return (
    <MainCard
      border
      content
      boxShadow
      shadow={theme.shadows[16]}
      title={
        <Grid container>
          <Grid item xs={6} textAlign="start">
            <Logo style={{ width: "26ch" }} />
          </Grid>
          <Grid item xs={6} textAlign="end">
            <Stack>
              <Typography>Date of Term Sheet</Typography>
              <Typography>{termsheetDate ?? '[customer approved date]'}</Typography>
            </Stack>
          </Grid>
        </Grid>
      }
      footer={
        <Stack
          p={2}
          spacing={2}
        >
          <Typography fontSize="0.8em" fontWeight={theme.typography.fontWeightRegular}>
            *The rate offered is tentative, based on an initial assessment of credit risk.
            Depending on the findings of the risk evaluation by Incomlend Pte Ltd
            during the onboarding stage, the final rate may be adjusted up or
            down by a maximum of 0.5%.<br />
            **All the fees will be automatically deducted from the funded amount.<br />
            ***The conditions specified in this Indicative term sheet remain in effect until
            two weeks from the date noted on the opening page. Your acceptance and payment of
            the Processing fee of this initial Term Sheet is requested to advance our application
            for authorization from the Risk and Compliance department.
          </Typography>
          <Typography fontSize="0.7em" fontWeight={theme.typography.fontWeightLight}>
            Overview and Confidentiality Agreement of Indicative Financing Terms by Incomlend Pte Ltd <br /><br />
            This indicative term sheet (“Term Sheet”) summarizes the principal terms, for negotiation purposes only,
            with respect to a potential financing arrangement by Incomlend Pte. Ltd.
            Save as otherwise specifically provided, nothing herein creates any legally binding obligation
            on the part of any party, nor shall any legally binding obligations arise unless and until the parties have
            executed definitive written agreements and obtained all requisite governmental,
            corporate, management and legal approval. <br />
            NDA: By signing this agreement, you agree to keep all sensitive information (including rates,
            fees payable, and other information acquired in consequence of this Term Sheet) confidential except where
            (a) you are entitled or bound to disclose such information under compulsion of law; or
            (b) it is reasonably necessary for the performance of the transaction envisaged under this Term Sheet.
            You will maintain third-party confidentiality.
            Incomlend can seek extra relief for any breach or threatened breach of
            confidentiality standards without proving loss or damage.
          </Typography>
        </Stack>
      }
    >
      <Stack spacing={2} direction="column">
        <MainCard
          border
          content
          headerSx={{ py: 2 }}
          title={<Typography>TERM SHEET - EXPORT FACTORING FACILITY</Typography>}
        >
          <Typography fontSize="0.8em" fontWeight={theme.typography.fontWeightLight}>
            Incomlend Pte Ltd is delighted to offer <b>{values?.customerName}</b> a preliminary
            indicative proposal for Export Factoring Facility. This digital solution allows
            for the easy submission of invoices related to transactions via Incomlend Pte Ltd ’s online platform.
            Please be aware that the availability of these facilities is contingent upon
            successful internal risk assessments, customer due diligence, and the acquisition of other necessary approvals.
          </Typography>
        </MainCard>
        <MainCard
          border
          content
          headerSx={{ py: 2 }}
          title={<Typography>PRODUCT DETAILS</Typography>}
        >
          <Grid container spacing={2}>
            <Grid item xs={3}><Typography>Product Type</Typography></Grid>
            <Grid item xs={3}><Typography>{values.product}</Typography></Grid>
            <Grid item xs={3}><Typography>Financing Type</Typography></Grid>
            <Grid item xs={3}><Typography>{values.financingType}</Typography></Grid>

            <Grid item xs={3}><Typography>Features</Typography></Grid>
            <Grid item xs={9} textAlign="start">
              <ul style={{ margin: 0, padding: 0 }}>
                <li>Incomlend Pte Ltd acquires complete rights, claims, and economic benefits of the assigned invoices</li>
                <li>Non-recourse, Buyer Notified, Post Shipping, Cross Border</li>
              </ul>
            </Grid>
            <Grid item xs={3}> <Typography> Advanced Amount** </Typography> </Grid>
            <Grid item xs={9}>
              <Typography>
                Upto {values.advancedAmount}% on eligible accounts receivable (Invoices) paid up front, the remaining
                collected funds are transferred to you after receipt of the same from the buyer
              </Typography>
            </Grid>
          </Grid>
        </MainCard>
        <MainCard
          border
          content
          headerSx={{ py: 2 }}
          title={<Typography>CLIENT DETAILS</Typography>}
        >
          <Grid container spacing={2} textAlign="start">
            <Grid item xs={3}> <Typography>Company Name</Typography></Grid>
            <Grid item xs={3}> <Typography>{values.clientDetails.companyName}</Typography></Grid>
            <Grid item xs={4}> <Typography>Country of Incorporation</Typography></Grid>
            <Grid item xs={2}> <Typography>{values.clientDetails.country}</Typography></Grid>

            <Grid item xs={3}> <Typography>Traded Goods  Description</Typography></Grid>
            <Grid item xs={9}> <Typography>{values.clientDetails.tradeGoodsDescription}</Typography></Grid>
          </Grid>
        </MainCard>
        <MainCard
          border
          content
          headerSx={{ py: 2 }}
          title={
            <Stack>
              <Typography>COSTS</Typography>
              <Typography fontSize="0.6em" fontWeight={theme.typography.fontWeightLight}>
                {termsheet?.discount_rate_type ? 'Annualized' : 'Spread'} Discount Rate (applied on invoice face value)*
              </Typography>
            </Stack>
          }
        >
          <Grid container spacing={2} textAlign="start">
            <Grid item xs={3}><Typography>Pre-Qualified {product === PRODUCTS.factoring ? 'Buyer' : 'Supplier'}</Typography></Grid>
            <Grid item xs={3}><Typography>{values.costs.preQualifiedPartner}</Typography></Grid>
            <Grid item xs={3}><Typography>Credit Limit</Typography></Grid>
            <Grid item xs={3}><Typography>$ {values.costs.creditLimit}</Typography></Grid>

            <Grid item xs={3}><Typography>Currency</Typography></Grid>
            <Grid item xs={3}><Typography>{values.costs.currency}</Typography></Grid>
            <Grid item xs={3}><Typography>{termsheet?.discount_rate_type ? 'Annualized' : 'Spread'} Discount Rate*</Typography></Grid>
            <Grid item xs={3}><Typography>{values.costs.annualizedDiscountRate}%</Typography></Grid>

            <Grid item xs={3}><Typography>Expected Tenor</Typography></Grid>
            <Grid item xs={9}><Typography>{values.costs.expected_tenure} days</Typography></Grid>

            <Grid item xs={3}><Typography>Facility Fee**</Typography></Grid>
            <Grid item xs={3}><Typography>{values.costs.facility_fee}% of annual invoice volume</Typography></Grid>
            <Grid item xs={3}><Typography>Setup Fee**</Typography></Grid>
            <Grid item xs={3}><Typography>${values.costs.setup_fee} due within 5 days of signing the Term Sheet</Typography></Grid>
            <Grid item xs={3}>
              <Typography>Annual Maintenance Fee**</Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography>
                ${parseFloat(values.costs.customer_maintenance_fee ?? `0`) + parseFloat(`${values.costs.partner_maintenance_fee ?? 0}`)} comprising ${values.costs.customer_maintenance_fee} for each direct customer and
                ${values.costs.partner_maintenance_fee} for each trade partner, is due upon financing in the
                first year and on the anniversary of the initial fee each year thereafter
              </Typography>
            </Grid>
          </Grid>
        </MainCard>
      </Stack>
    </MainCard>
  )
}

export default TermSheetPreview;
