import { PictureAsPdf } from "@mui/icons-material";
import SyncIcon from "@mui/icons-material/Sync";
import {
  Alert,
  AlertTitle,
  Box,
  Chip,
  Divider,
  Grid,
  IconButton,
  Link,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme
} from "@mui/material";
import {
  useGetTermsheetBillByIdQuery,
  useGetTermsheetBillQuery,
  useMarkInvoiceAsPaidMutation
} from "api/api-accounts";
import { useGetCurrentUserQuery } from "api/api-users-v2";
import LogoDesktop from "assets/logo.svg?react";
import {
  BILLING_STATUSES,
  BILLINGS_LINK,
  INCOMLEND_INTERNAL_LINK,
} from "codes";
import StatusDisplay from "components/Billings/StatusDisplay";
import Layout from "components/Common/Layout";
import Modal from "components/Common/Modal";
import SkeletonLoad from "components/Common/SkeletonLoad";
import CountdownSettingsDrawer from "components/Company/Lead/CountdownSettings";
import DayCounter from "components/DayCounter/DayCounter";
import { Form, Formik } from "formik";
import useIsMobile from "hooks/useIsMobile";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import moment from "moment";
import { PERMISSIONS } from "permissions";
import React, { useState } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { useAppSelector } from "redux/hooks";
import * as Yup from "yup";
import ProofOfPaymentDocumentList from "./ProofOfPayment";
import { useGetConfigurationQuery } from "api/api-configuration";

const InvoiceSchema = Yup.object().shape({
  invoiceDate: Yup.date().required("Required"),
  invoiceNo: Yup.string().required("Required"),
  description: Yup.string().required("Required"),
  quantity: Yup.number().required("Required"),
  unitPrice: Yup.number().required("Required"),
  gstRate: Yup.number().required("Required"),
  amountExclGST: Yup.number().required("Required"),
});
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#ededed",
    padding: 1,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "1.1em",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({}));
const StyledStack = styled(Stack)(({ theme }) => ({
  "@media print": {
    "-webkit-print-color-adjust": "exact",
    "print-color-adjust": "exact",
  },

  background: "#ffffff",
  border: "1px solid #eee",
  padding: theme.spacing(4),
  width: "100%",
  height: "100%",
  borderRadius: theme.shape.borderRadius,
}));
const Billing: React.FC = () => {
  const isMobile = useIsMobile();
  const [shrinkFactor, setShrinkFactor] = useState(1);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [previewContainerSize, setPreviewContainerSize] = useState({
    width: 0,
    height: 0,
  });

  const [showPaidModal, setShowPaidModal] = useState(false);

  const bill_id = useParams<{ id: string }>()?.id;
  const { data: curr_user } = useGetCurrentUserQuery();
  const isInternal = curr_user?.is_internal;
  const {
    data: billByName,
    isFetching: isFetchingBillByName,
    refetch: refetchBillByName,
  } = useGetTermsheetBillQuery(bill_id ?? "", {
    skip: !Boolean(bill_id) || !isInternal,
  });

  const {
    data: billById,
    isFetching: isFetchingBillById,
    refetch: refetchBillById,
  } = useGetTermsheetBillByIdQuery(bill_id ?? "", {
    skip: !Boolean(bill_id) || isInternal,
  });

  const { data: settings } = useGetConfigurationQuery(undefined)

  const [markAsPaid, { isLoading: isLoadingMarkAsPaid }] =
    useMarkInvoiceAsPaidMutation();

  const bill = isInternal ? billByName : billById;
  const isFetchingBill = isInternal ? isFetchingBillByName : isFetchingBillById;

  const USER_PERMISSION = useAppSelector(
    (state) => state.appState.user_permission
  );

  const isHod = USER_PERMISSION.includes(PERMISSIONS.termsheet_approver);
  const isFinance = USER_PERMISSION.includes(PERMISSIONS.termsheet_financing);

  const sizeHuge = `${1.3 * shrinkFactor}em`;
  const sizeLarge = `${1.1 * shrinkFactor}em`;
  const sizeMedium = `${0.9 * shrinkFactor}em`;
  const sizeSmall = `${0.7 * shrinkFactor}em`;
  const captionSize = `${0.7 * shrinkFactor}em`;
  const theme = useTheme();

  // const CustomButton = styled(Button)(({ theme }) => ({
  //   backgroundColor: "#FFFFFF",
  //   color: "#001F54",
  //   border: "2px solid #001F54",
  //   "&:hover": {
  //     backgroundColor: "#f0f8ff",
  //     borderColor: "#001F54",
  //   },
  // }));

  const getExpiry = () => {
    if (!bill?.expiry_info?.expiry_date) {
      return new Date();
    }
    return moment(bill?.expiry_info?.expiry_date).toDate();
  };

  const downloadDivAsPdf = async () => {
    const div = document.getElementById("billing-invoice");

    if (div) {
      try {
        const canvas = await html2canvas(div, {
          scale: 5,
        });
        const imgData = canvas.toDataURL("image/jpeg", 1);

        const pdf = new jsPDF({
          orientation: "landscape",
          unit: "pt",
          format: "a4",
        });
        const imgWidth = 842; // A4 width in pt
        const imgHeight = (canvas.height * imgWidth) / canvas.width; // Maintain aspect ratio
        pdf.addImage(
          imgData,
          "JPEG",
          0,
          0,
          imgWidth,
          imgHeight,
          "billing-invoice",
          "NONE"
        );
        pdf.save(bill_id);
      } catch (error) {
        console.error("Failed to generate PDF", error);
      }
    }
  };
  return (
    <Layout
      title={
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          <Stack spacing={1}>
            <Typography fontSize={"1.2em"}>Invoice</Typography>
          </Stack>
          <Chip
            label={"Setup Fee"}
            sx={{ height: "2em", ml: 2 }}
            variant="outlined"
          />
          {isFetchingBill && <SkeletonLoad bars={1} />}
          {!isFetchingBill && bill && (
            <StatusDisplay
              status={bill?.invoice_status}
              style={{ minWidth: "fit-content", fontSize: "0.5em" }}
            />
          )}
          <IconButton
            onClick={() => {
              isInternal ? refetchBillByName() : refetchBillById();
            }}
          >
            <SyncIcon />
          </IconButton>
          <DayCounter
            minDay={settings?.TERMSHEET.invoice_expiry_days ?? 5}
            date={getExpiry()}
            isDrawer={isHod}
            setDrawerOpen={setDrawerOpen}
            drawerOpen={drawerOpen}
            direction="column"
            disabled={!(bill?.expiry_info?.is_active ?? true)}
          />
        </Stack>
      }
      backArrow
      backLink={
        curr_user?.is_internal
          ? `${INCOMLEND_INTERNAL_LINK}${BILLINGS_LINK}`
          : ""
      }
      headerConfig={{
        left: 5,
        mid: 0,
        right: 0,
      }}
      mode="default"
      alignItems="center"
      primary={{
        startIcon: <PictureAsPdf />,
        children: "Download",
        onClick: () => downloadDivAsPdf(),
        fullWidth: true,
        style: { width: "fit-content" },
      }}
      secondary={
        isFinance
          ? {
              children: "Paid",
              onClick: () => setShowPaidModal(true),
              fullWidth: true,
              color: "success",
              variant: "contained",
              disabled:
                !isFinance || bill?.invoice_status === BILLING_STATUSES.PAID,
              isLoading: isLoadingMarkAsPaid,
            }
          : undefined
      }
    >
      {isFetchingBill && <SkeletonLoad bars={10} />}
      {!isFetchingBill && bill && (
        <React.Fragment>
          <Modal
            message={
              <Typography>{`You are approving the payment of this bill ${bill?.internal_name}`}</Typography>
            }
            open={showPaidModal}
            modalHeaderSx={{ background: "transparent" }}
            onClose={() => setShowPaidModal(false)}
            width={isMobile ? "90%" : "25%"}
            modalOverlaySx={{
              backgroundColor: `${theme.palette.background.default} !important`,
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "4ch",
              padding: "2ch 3ch",
              fontSize: `${theme.typography.fontSize}px !important`,
              fontFamily: `${theme.typography.fontFamily} !important`,
              fontWeight: "inherit !important",
            }}
            height="auto"
            closeButton
            primary={{
              children: "Confirm",
              color: "primary",
              onClick: () => {
                markAsPaid(bill.id)
                  .unwrap()
                  .then(() => toast.success("Marked invoice as paid"))
                  .catch((err) => toast.error(err.message));
                setShowPaidModal(false);
              },
            }}
            secondary={{
              children: "Cancel",
              color: "secondary",
              onClick: () => {
                setShowPaidModal(false);
              },
            }}
          />
          <Grid
            container
            spacing={2}
            alignItems="top"
            sx={{
              bgcolor: "background.default",
              minHeight: "550pt",
              borderRadius: 1,
              pb: 2,
            }}
          >
            <Grid item xs={10} lg={12}>
              <Stack
                sx={{
                  aspectRatio: "1.414/1 auto",
                  resize: "both",
                  width: `${100 * shrinkFactor}%`,
                  height: previewContainerSize.width * 1.414,
                  maxHeight: "842pt",
                }}
                id="preview-container"
              >
                <Box>
                  <Stack
                    direction="row"
                    sx={{
                      width: "100%",
                      height: "100%",
                      overflow: "hidden",
                    }}
                  >
                    <StyledStack id="billing-invoice">
                      {/* Header Section */}

                      <Stack
                        direction="row"
                        spacing={2}
                        justifyContent="space-between"
                      >
                        <LogoDesktop width="30%" height="auto" />
                        <Stack>
                          <Typography variant="h4" fontSize={sizeLarge}>
                            Incomlend Pte Ltd
                          </Typography>
                          <Typography fontSize={sizeLarge}>
                            112 Robinson Road, #12-03A
                          </Typography>
                          <Typography fontSize={sizeLarge}>
                            Singapore 068902
                          </Typography>
                        </Stack>
                      </Stack>
                      <Divider sx={{ my: 2 }} />
                      {/* Invoice To Section */}
                      <Stack
                        mb={4}
                        direction="row"
                        justifyContent="space-between"
                      >
                        <Stack>
                          <Typography
                            variant="h4"
                            my={3}
                            textAlign="start"
                            fontSize={sizeHuge}
                          >
                            Invoice To:
                          </Typography>
                          <Typography fontSize={sizeMedium}>
                            Name: {` ${bill?.lead?.name}`}
                          </Typography>
                          <Typography fontSize={sizeMedium}>
                            Address:{" "}
                            {` ${bill?.lead?.address_line1 ?? ""}, ${bill?.lead?.address_line2 ?? ""}, ${bill?.lead?.city ?? ""}, ${bill?.lead?.country ?? ""}, ${bill?.lead?.postcode ?? ""}`}
                          </Typography>
                          {/* <Typography fontSize={sizeMedium}>
                            Email: NA
                          </Typography> */}
                          {/* <Typography fontSize={sizeMedium}>
                            Attn: Honkai
                          </Typography> */}
                        </Stack>
                        <Stack>
                          <Typography
                            variant="h3"
                            textAlign="start"
                            my={3}
                            fontWeight="bolder"
                            fontSize={sizeHuge}
                          >
                            Fees Invoice
                          </Typography>
                          {/* <Typography fontSize={sizeMedium}>
                            Attn: Honkai
                          </Typography> */}
                          <Typography textAlign="start" fontSize={sizeMedium}>
                            Invoice Date:{" "}
                            {` ${moment(bill?.created_at).format("DD/MM/YYYY")}`}
                          </Typography>
                          <Typography textAlign="start" fontSize={sizeMedium}>
                            Billing Id: {` ${bill?.reference_number}`}
                          </Typography>
                          {/* <Typography textAlign="start" fontSize={sizeMedium}>
                            Term sheet Id: 01-01 12 Huanpu Road
                          </Typography> */}
                        </Stack>
                      </Stack>

                      {/* Form Section */}
                      <Formik
                        initialValues={bill}
                        // validationSchema={InvoiceSchema}
                        enableReinitialize
                        onSubmit={() => {}}
                      >
                        {({
                          values,
                          handleChange,
                          handleBlur,
                          setFieldValue,
                        }) => (
                          <Form>
                            <TableContainer>
                              <Table aria-label="invoice table">
                                <TableHead
                                  sx={{
                                    backgroundColor: "#f5f5f5",
                                    "& .MuiTableCell-root": {
                                      fontWeight: "bold",
                                    },
                                    ".MuiTableCell-head": {
                                      fontSize: sizeSmall,
                                    },
                                  }}
                                >
                                  <StyledTableRow>
                                    <StyledTableCell
                                      sx={{
                                        textAlign: "center",
                                      }}
                                    >
                                      Date of Service
                                    </StyledTableCell>
                                    <StyledTableCell
                                      sx={{
                                        textAlign: "left",
                                      }}
                                    >
                                      Description of Service
                                    </StyledTableCell>
                                    <StyledTableCell>Quantity</StyledTableCell>
                                    <StyledTableCell
                                      sx={{
                                        textAlign: "right",
                                      }}
                                    >
                                      Unit Price
                                    </StyledTableCell>
                                    <StyledTableCell
                                      sx={{
                                        textAlign: "right",
                                      }}
                                    >
                                      GST Rate
                                    </StyledTableCell>
                                    <StyledTableCell
                                      sx={{
                                        textAlign: "right",
                                      }}
                                    >
                                      Amount (Excl. GST)
                                    </StyledTableCell>
                                  </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                  <StyledTableRow>
                                    <StyledTableCell>
                                      <Typography
                                        sx={{
                                          fontSize: captionSize,
                                          textAlign: "center",
                                        }}
                                      >
                                        {moment(values?.created_at).format(
                                          "DD/MM/YYYY"
                                        )}
                                      </Typography>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      <Typography
                                        sx={{
                                          fontSize: captionSize,
                                          textAlign: "left",
                                        }}
                                      >
                                        Setup fee
                                      </Typography>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      <Typography
                                        sx={{
                                          fontSize: captionSize,
                                          textAlign: "left",
                                        }}
                                      >
                                        1
                                      </Typography>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      <Typography
                                        sx={{
                                          fontSize: captionSize,
                                          textAlign: "right",
                                        }}
                                      >
                                        {values?.amount_paid}
                                      </Typography>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      <Typography
                                        sx={{
                                          fontSize: captionSize,
                                          textAlign: "right",
                                        }}
                                      >
                                        {values?.gst_rate}
                                      </Typography>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      <Typography
                                        sx={{
                                          fontSize: captionSize,
                                          textAlign: "right",
                                        }}
                                      >
                                        {values?.amount_paid}
                                      </Typography>
                                    </StyledTableCell>
                                  </StyledTableRow>
                                  {/* Invoice & External Reference */}
                                </TableBody>
                              </Table>
                            </TableContainer>
                            {/* Calculation Section */}
                            <Box mt={1}>
                              <Grid
                                container
                                spacing={2}
                                justifyContent="flex-end"
                              >
                                <Grid item xs={5}>
                                  <Stack spacing={0.6}>
                                    <Stack
                                      direction="row"
                                      justifyContent="space-between"
                                    >
                                      <Typography
                                        fontSize={sizeSmall}
                                        textAlign="start"
                                      >
                                        Total Amount (Excl. GST):{" "}
                                      </Typography>
                                      <Typography
                                        fontSize={sizeSmall}
                                        textAlign="end"
                                      >
                                        {values?.amount_paid}
                                      </Typography>
                                    </Stack>

                                    <Stack
                                      direction="row"
                                      justifyContent="space-between"
                                    >
                                      <Typography
                                        fontSize={sizeSmall}
                                        textAlign="start"
                                      >
                                        GST Amount:{" "}
                                      </Typography>
                                      <Typography
                                        fontSize={sizeSmall}
                                        textAlign="end"
                                      >
                                        {values?.gst_rate * values?.amount_paid}
                                      </Typography>
                                    </Stack>

                                    <Stack
                                      direction="row"
                                      justifyContent="space-between"
                                    >
                                      <Typography
                                        fontSize={sizeSmall}
                                        textAlign="start"
                                      >
                                        Total Amount (Incl. GST):{" "}
                                      </Typography>
                                      <Typography
                                        fontSize={sizeSmall}
                                        textAlign="end"
                                      >
                                        {values?.amount_paid +
                                          values?.gst_rate *
                                            values?.amount_paid}
                                      </Typography>
                                    </Stack>

                                    <Stack
                                      direction="row"
                                      justifyContent="space-between"
                                      sx={{
                                        backgroundColor: "#ededed",
                                        p: 0.8,
                                      }}
                                    >
                                      <Typography
                                        fontSize={sizeSmall}
                                        textAlign="start"
                                        fontWeight="bolder"
                                      >
                                        Paid Amount:{" "}
                                      </Typography>
                                      <Typography
                                        fontSize={sizeSmall}
                                        textAlign="end"
                                        fontWeight="bolder"
                                      >
                                        {values?.amount_paid}
                                      </Typography>
                                    </Stack>
                                    {/* <Stack
                                      direction="row"
                                      justifyContent="space-between"
                                      sx={{
                                        backgroundColor: "#ededed",
                                        p: 0.8,
                                      }}
                                    >
                                      <Typography
                                        fontSize={sizeSmall}
                                        textAlign="start"
                                        fontWeight="bold"
                                      >
                                        Balance Due:{" "}
                                      </Typography>
                                      <Typography
                                        fontSize={sizeSmall}
                                        textAlign="end"
                                        fontWeight="bold"
                                      >
                                        0.00
                                      </Typography>
                                    </Stack> */}
                                  </Stack>
                                </Grid>
                              </Grid>
                            </Box>
                            {/* Footer Section */}
                            <Stack
                              mt={4}
                              textAlign="center"
                              sx={{ width: "100%" }}
                            >
                              <Alert
                                variant="filled"
                                severity="info"
                                sx={{
                                  "&.MuiPaper-root": {
                                    width: "100%",
                                    backgroundColor: "#ededed",
                                    color: "#000",
                                    "& .MuiAlert-message": {
                                      width: "100%",
                                      justifyContent: "start",
                                    },
                                    ".MuiAlert-icon": {
                                      display: "none",
                                    },
                                  },
                                }}
                              >
                                <AlertTitle
                                  textAlign="start"
                                  fontWeight="bold"
                                  fontSize={sizeMedium}
                                >
                                  Disclaimer
                                </AlertTitle>
                                <Divider />
                                <Stack>
                                  <Typography
                                    fontSize={captionSize}
                                    textAlign="start"
                                  >
                                    This is a computer-generated invoice. No
                                    signature is required.
                                  </Typography>
                                  <Typography
                                    fontSize={captionSize}
                                    textAlign="start"
                                  >
                                    Please note that any sums owed by you to
                                    Incomlend may be directly deducted from your
                                    e-Wallet balance, as authorized by you in
                                    the [Accounts Receivable Purchase
                                    Agreement/Invoice Purchase Agreement].
                                  </Typography>
                                  <Typography
                                    fontSize={captionSize}
                                    textAlign="start"
                                  >
                                    {`For General Terms and Conditions, please refer to `}
                                    <Link
                                      href="https://prod.backend.incomlend.com/static/public/terms_and_conditions.pdf"
                                      target="_blank"
                                    >
                                      Terms and Conditions
                                    </Link>
                                  </Typography>
                                </Stack>
                              </Alert>
                              <Stack
                                mt={4}
                                direction="row"
                                spacing={2}
                                justifyContent="space-evenly"
                                sx={{
                                  backgroundColor: "#ededed",
                                  p: 1,
                                  borderRadius: 1,
                                }}
                              >
                                <Typography
                                  textAlign="start"
                                  fontSize={captionSize}
                                >
                                  <strong> GST Registration No.: </strong>
                                  201600941W
                                </Typography>
                                <Typography
                                  textAlign="start"
                                  fontSize={captionSize}
                                >
                                  <strong> Phone: </strong>
                                  +65 3158 2540
                                </Typography>
                                <Typography
                                  textAlign="start"
                                  fontSize={captionSize}
                                >
                                  <strong> Address: </strong>
                                  112 Robinson Road, #12-03A, Singapore 068902
                                </Typography>
                              </Stack>
                            </Stack>
                          </Form>
                        )}
                      </Formik>
                    </StyledStack>
                  </Stack>
                </Box>
              </Stack>
            </Grid>
          </Grid>
          <CountdownSettingsDrawer
            id={bill?.expiry_info?.id}
            drawerOpen={drawerOpen}
            setDrawerOpen={setDrawerOpen}
            date={getExpiry()}
            defaultDays={5}
            refetch={() => isInternal ? refetchBillByName() : refetchBillById()}
          />
          <ProofOfPaymentDocumentList
            initialValues={{}}
            entity_id={bill.id}
            disabled={bill.invoice_status === BILLING_STATUSES.PAID}
          />
        </React.Fragment>
      )}
    </Layout>
  );
};

export default Billing;
