import { Typography, useTheme, type ButtonProps } from "@mui/material";
import { REJECTED_BY_SALES } from "codes";
import { Form, Formik } from "formik";

import {
  useApproveCreditLimitMutation,
  useContactCreditLimitCreatorMutation,
  useGetCreditLimitQuery,
  useRejectCreditLimitMutation,
  useResubmitCreditLimitMutation,
} from "api/api-origination";
import Modal from "components/Common/Modal";
import React from "react";
import { isMobile } from "react-device-detect";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import IncomlendReview from "../IncomlendReview";

interface ReviewModalProps {
  showIncomlendReviewModal: {
    visible: boolean;
  };
  setShowIncomlendReviewModal: React.Dispatch<
    React.SetStateAction<{
      visible: boolean;
    }>
  >;
}

const ReviewModal: React.FC<ReviewModalProps> = ({
  showIncomlendReviewModal,
  setShowIncomlendReviewModal,
}) => {
  const theme = useTheme();
  const { id } = useParams();

  const { data: creditLimit, refetch } = useGetCreditLimitQuery(id ?? "", {
    skip: !Boolean(id),
  });

  const [contactCreditLimitCreator, { isLoading: isLoadingContactCreator }] =
    useContactCreditLimitCreatorMutation();
  const [resubmitCreditLimit, { isLoading: isLoadingResubmit }] =
    useResubmitCreditLimitMutation();
  const [approveCreditLimit, { isLoading: isLoadingApproval }] =
    useApproveCreditLimitMutation();
  const [rejectCreditLimit, { isLoading: isLoadingRejectCL }] =
    useRejectCreditLimitMutation();

  const modalOverlaySx = {
    backgroundColor: "white !important",
    width: "50%",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "4ch",
    padding: "2ch",
    fontSize: `${theme.typography.fontSize}px !important`,
    fontFamily: `${theme.typography.fontFamily} !important`,
    height: "inherit !important",
    fontWeight: "inherit !important",
  };
  const modalSx = {
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  };
  const initialIncomlendReviewModalValues: {
    comments: string;
    action: "reject" | "contact_creator" | "resubmit" | "approve";
  } = {
    comments: "",
    action: "contact_creator",
  };

  const getIncomlendReviewButtonText = (status: string) => {
    switch (status) {
      case "approve":
        return { color: "success", text: "Approve Credit Limit" };
      case "contact_creator":
        return { color: "primary", text: "Request changes" };
      case "reject":
        return { color: "error", text: "Reject Credit Limit" };
      case "resubmit":
        return { color: "primary", text: "Resubmit Credit Limit" };
      default:
        return { color: "primary", text: "Request changes" };
    }
  };

  const LOADER_DICTIONARY = {
    contact_creator: isLoadingContactCreator,
    resubmit: isLoadingResubmit,
    approve: isLoadingApproval,
    reject: isLoadingRejectCL,
  };

  const confirmClick = ({
    credit_limit_id,
    comments,
    action,
  }: {
    credit_limit_id: string;
    comments: string;
    action: "reject" | "contact_creator" | "resubmit" | "approve";
  }) => {
    if (action === "reject") {
      rejectCreditLimit({
        credit_limit_id: credit_limit_id,
        comments,
      })
        .unwrap()
        .catch((error) => {
          const errorFile = error?.message;
          toast(`Something went wrong : ${errorFile}`, {
            type: "error",
          });
        })
        .then(refetch);
    }
    if (action === "contact_creator") {
      contactCreditLimitCreator({
        credit_limit_id,
        comments: {
          comments,
        },
      })
        .unwrap()
        .catch((error) => {
          const errorFile = error?.message;
          toast(`Something went wrong : ${errorFile}`, {
            type: "error",
          });
        })
        .then(refetch);
    }
    if (action === "resubmit") {
      resubmitCreditLimit({
        credit_limit_id: credit_limit_id,
        comments: {
          comments,
        },
      })
        .unwrap()
        .catch((error) => {
          const errorFile = error?.message;
          toast(`Something went wrong : ${errorFile}`, {
            type: "error",
          });
        })
        .then(refetch);
    }
    if (action === "approve") {
      approveCreditLimit({
        credit_limit_id: credit_limit_id,
        comments: {
          comments,
        },
      })
        .unwrap()
        .catch((error) => {
          const errorFile = error?.message;
          toast(`Something went wrong : ${errorFile}`, {
            type: "error",
          });
        })
        .then(refetch);
    }
    setShowIncomlendReviewModal({
      visible: false,
    });
  };

  return (
    <Formik
      onSubmit={console.log}
      initialValues={initialIncomlendReviewModalValues}
    >
      {(formikProps) => (
        <Form>
          <Modal
            Header={
              <Typography fontWeight="bold">Submit Your Review</Typography>
            }
            message={
              id &&
              creditLimit && (
                <IncomlendReview
                  cl_id={id}
                  cl_creator={creditLimit?.creator}
                  is_rejected={
                    creditLimit?.internalStatus === REJECTED_BY_SALES
                  }
                  send_message_to={{
                    contact_description: creditLimit?.contact_description,
                    email: creditLimit?.email,
                    name: creditLimit?.client_name,
                  }}
                  salesName={creditLimit?.salesName}
                />
              )
            }
            sx={modalSx}
            width={isMobile ? "90%" : "40%"}
            height="auto"
            modalContentSx={{ height: "auto" }}
            open={showIncomlendReviewModal.visible}
            modalOverlaySx={modalOverlaySx}
            modalFooterSx={{
              maxWidth: "100%",
              justifyContent: "space-between",
            }}
            onClose={() =>
              setShowIncomlendReviewModal({
                visible: false,
              })
            }
            closeButton
            primary={{
              variant: "contained",
              fullWidth: true,
              loading: LOADER_DICTIONARY[formikProps.values.action],
              onClick: () => {
                confirmClick({
                  credit_limit_id: creditLimit?.id ?? "",
                  comments: formikProps.values.comments,
                  action: formikProps.values.action,
                });
              },
              children: getIncomlendReviewButtonText(formikProps.values.action)
                .text,
              sx: { minWidth: "12vw" },
              color: getIncomlendReviewButtonText(formikProps.values.action)
                .color as ButtonProps["color"],
            }}
            secondary={{
              variant: "outlined",
              fullWidth: true,
              onClick: () =>
                setShowIncomlendReviewModal({
                  visible: false,
                }),
              children: `Cancel`,
            }}
          />
        </Form>
      )}
    </Formik>
  );
};

export default ReviewModal;
