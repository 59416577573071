import { createApi } from "@reduxjs/toolkit/query/react";
import { VITE_API_V2_URL } from "codes";
import { axiosBaseQuery } from "helpers/axiosbaseQuery";
import type { PlatformUsageSummary } from "./api-marketing.d";

// Define the response type for individual platform usage metrics
export interface PlatformUsage {
  source: string;
  label: string;
  signups: number;
  submitted_companies: number;
  tracked_shipments: number;
  submitted_credit_limits: number;
  approved_credit_limits: number;
  rejected_credit_limits: number;
  started_onboardings: number;
  submitted_onboardings: number;
}

// Function to format the response data for individual platform usage metrics
const formatPlatformUsage = (data: PlatformUsage[]) =>
  data?.map((item) => ({
    source: item?.source,
    label: item?.label,
    signups: item?.signups,
    submitted_companies: item?.submitted_companies,
    tracked_shipments: item?.tracked_shipments,
    submitted_credit_limits: item?.submitted_credit_limits,
    approved_credit_limits: item?.approved_credit_limits,
    rejected_credit_limits: item?.rejected_credit_limits,
    started_onboardings: item?.started_onboardings,
    submitted_onboardings: item?.submitted_onboardings,
  }));

export const marketing = createApi({
  reducerPath: "marketing",
  tagTypes: ["USAGE"],
  baseQuery: axiosBaseQuery({
    baseUrl: `${VITE_API_V2_URL}/marketing`,
  }),
  endpoints: (builder) => ({
    getPlatformUsage: builder.query<PlatformUsage[], null>({
      query: () => ({
        url: `/get-platform-usage`,
        method: "get",
      }),
      transformResponse: (response) => formatPlatformUsage(response?.data),
      providesTags: ["USAGE"],
    }),
    generateTest: builder.mutation<any, string>({
      query: (data) => {
        const email = data;
        return {
          url: `/customer-io-test`,
          method: "POST",
          data: { email },
        };
      },
      transformResponse: (response) => response?.data,
    }),
    getPlatformUsageSummary: builder.query<PlatformUsageSummary, null>({
      query: () => ({
        url: `/get-platform-usage-summary`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["USAGE"],
    }),
  }),
});

export const {
  useGetPlatformUsageQuery,
  useGenerateTestMutation,
  useGetPlatformUsageSummaryQuery, // New query hook for summary
} = marketing;
