import { Box, Stack } from "@mui/material";
import { useEditOriginationTradeCommentsMutation } from "api/api-maps";
import VariableInput from "components/Common/VariableInput";
import { Form, Formik } from "formik";
import { MapContext } from "pages/maps/map";
import React from "react";
import { toast } from "react-toastify";
import type { TradePresentation } from "types";
import AccountInfo from "./CustomerInfo";
import TradeInfo from "./TradeInfo";
import TradePartnerInfo from "./TradePartnerInfo";

const InfoPanes: React.FC<TradePresentation> = ({
  customer,
  trade_partner,
  deal_origin,
  trade_comments,
  requested_facility,
  first_invoice_expected_amount,
  first_invoice_expected_date,
  tenor,
}) => {
  const mapName = React.useContext(MapContext);
  const [editTradeComments] = useEditOriginationTradeCommentsMutation();
  const editor = (field: string, value: any, friendlyLabel: string) => {
    if (value === null || value === undefined || value === "") return;
    mapName &&
      editTradeComments({
        map_name: mapName,
        data: { [field]: value },
      })
        .unwrap()
        .then(() => {
          toast.success(`${friendlyLabel} Updated`);
        })
        .catch((err) => toast.error(`Error: ${err?.message}`));
  };
  return (
    <Formik
      initialValues={{
        customer,
        trade_partner,
        deal_origin,
        trade_comments,
        requested_facility,
        first_invoice_expected_amount,
        first_invoice_expected_date,
        tenor,
      }}
      onSubmit={console.log}
    >
      {(formikProps) => {
        return (
          <Form>
            <Stack spacing={2}>
              <Box p={2}>
                <VariableInput
                  name={"deal_origin"}
                  label={"Origin of the deal"}
                  sx={{
                    ".MuiInputBase-root": {
                      height: "15vh",
                      backgroundClip: "border-box",
                      borderRadius: "10px",
                      textarea: {
                        height: "13vh !important",
                        overflow: "scroll !important",
                      },
                    },
                  }}
                  value={formikProps.values.deal_origin}
                  placeholder="Enter details here"
                  richText
                  handleSave={(value) => {
                    editor("deal_origin", value, "Origin of the deal");
                  }}
                  {...formikProps}
                />
              </Box>
              <Box p={2}>
                <AccountInfo {...customer} />
              </Box>
              <Box p={2}>
                <TradePartnerInfo {...trade_partner} />
              </Box>
              <Box p={2}>
                <TradeInfo
                  {...{
                    trade_comments,
                    requested_facility,
                    first_invoice_expected_amount,
                    first_invoice_expected_date,
                    tenor,
                  }}
                />
              </Box>
            </Stack>
          </Form>
        );
      }}
    </Formik>
  );
};

export default InfoPanes;
