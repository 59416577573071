import { Divider, Stack, Typography } from "@mui/material";
import { useEditOriginationTradeCommentsMutation } from "api/api-maps";
import MainCard from "components/Common/Cards/MainCard";
import VariableInput from "components/Common/VariableInput";
import { Form, Formik } from "formik";
import { currencyFormatter } from "helpers/currencyFormatter";
import { MapContext } from "pages/maps/map";
import React from "react";
import { toast } from "react-toastify";

interface TradeInfoProps {
  trade_comments: string;
  requested_facility: number;
  first_invoice_expected_amount: number;
  first_invoice_expected_date: string;
  tenor: string;
}

const TradeInfo: React.FC<TradeInfoProps> = ({
  trade_comments,
  requested_facility,
  first_invoice_expected_amount,
  first_invoice_expected_date,
  tenor,
}) => {
  const mapName = React.useContext(MapContext);
  const [editTradeComments] = useEditOriginationTradeCommentsMutation();
  const editor = (field: string, value: any, friendlyLabel: string) => {
    if (value === null || value === undefined || value === "") return;
    mapName &&
      editTradeComments({
        map_name: mapName,
        data: { [field]: value },
      })
        .unwrap()
        .then(() => {
          toast.success(`${friendlyLabel} Updated`);
        })
        .catch((err) => toast.error(`Error: ${err?.message}`));
  };
  const formikWrapper = {
    initialValues: {
      requested_facility,
      first_invoice_expected_amount,
      first_invoice_expected_date,
      tenor: tenor,
      trade_comments,
    },

    onSubmit: console.log,
  };
  return (
    <MainCard
      title={`Trade Details`}
      headerSx={{
        p: 1,
      }}
      contentSX={{
        p: 1,
        backgroundColor: "grey.300",
      }}
    >
      <Formik {...formikWrapper}>
        {(formik) => (
          <Form>
            <Stack spacing={2} p={2} component="form">
              <Stack spacing={2} direction="row">
                <Typography fontWeight="bold">Requested Facility</Typography>
                <Typography>
                  {currencyFormatter({
                    amount: requested_facility,
                    currency: "USD",
                  })}
                </Typography>
              </Stack>
              <Stack spacing={2} direction="row">
                <Typography fontWeight="bold">Tenor</Typography>
                <Typography>
                  {formik.values.tenor ?? "Not Available"}
                </Typography>
              </Stack>

              <Divider />
              <Typography
                variant="h4"
                sx={{
                  textDecoration: "underline",
                }}
              >
                First Invoice Details
              </Typography>
              <VariableInput
                name="first_invoice_expected_amount"
                label="Expected Amount"
                value={formik.values.first_invoice_expected_amount}
                onChange={formik.handleChange}
                type="money"
                fullWidth
                noHover
                disabled
              />

              <VariableInput
                name="first_invoice_expected_date"
                label="Expected Date"
                value={formik.values.first_invoice_expected_date}
                onChange={formik.handleChange}
                fullWidth
                noHover
                disabled
              />
              <Divider />
              <Stack
                sx={{
                  backgroundColor: "#ffffff",
                  borderRadius: 1,
                  padding: 1,
                  boxShadow: 1,
                }}
                spacing={2}
              >
                <VariableInput
                  name="trade_comments"
                  label="Trade Comment"
                  value={formik.values.trade_comments}
                  onChange={formik.handleChange}
                  sx={{
                    ".MuiInputBase-root": {
                      height: "15vh",
                      backgroundClip: "border-box",
                      borderRadius: "10px",
                      textarea: {
                        height: "13vh !important",
                        overflow: "scroll !important",
                      },
                    },
                  }}
                  placeholder="Enter details here"
                  richText
                  handleSave={(value) => {
                    editor("trade_comments", value, "Trade Comment");
                  }}
                  {...formik}
                />
              </Stack>
            </Stack>
          </Form>
        )}
      </Formik>
    </MainCard>
  );
};

export default TradeInfo;
