import { Container, Stack, Typography } from "@mui/material";
import { useGetMapSummaryQuery, useGetOriginationTabQuery } from "api/api-maps";
import MainCard from "components/Common/Cards/MainCard";
import { MapContext } from "pages/maps/map";
import React from "react";

const OriginOfTheDeal: React.FC = () => {
  const mapName = React.useContext(MapContext);

  const { data: mapSummary } = useGetMapSummaryQuery(mapName ?? "", {
    skip: mapName === undefined,
  });

  const { data: tabData, isLoading: isLoadingTabData } =
    useGetOriginationTabQuery(mapName ?? "", {
      skip: mapName === undefined,
    });

  return (
    <Container>
      <Stack justifyContent="center" alignItems="center" pt={2} spacing={2}>
        <Typography variant="h2" textAlign="center">
          Origin of the Deal
        </Typography>
        <MainCard
          content
          boxShadow
          contentSX={{
            bgcolor: "#a2a6c338",
            px: 0.5,
            py: 0.25,
            ":last-child": {
              p: 1,
            },
          }}
        >
          <Stack
            dangerouslySetInnerHTML={{
              __html: tabData?.deal_origin ?? "Not Specified",
            }}
            margin="auto"
            sx={{
              ".ql-align-justify": {
                margin: 0,
                marginBottom: 0.25,
                fontSize: "0.5rem",
              },
            }}
          />
        </MainCard>
      </Stack>
    </Container>
  );
};

export default OriginOfTheDeal;
