import LoadingButton from "@mui/lab/LoadingButton";
import {
  Button,
  FormHelperText,
  Grid,
  InputAdornment,
  Typography,
  useTheme
} from "@mui/material";
import {
  useCreateAccountMutation,
  useGetAccountQuery,
  useUpdateAccountMutation,
  useUpdateStepsMutation,
} from "api/api-accounts";
import { useGetIndustriesQuery } from "api/api-company";
import { useGetCountriesByTypeQuery } from "api/api-misc";
import { useGetSalesCodesQuery } from "api/api-origination";
import {
  useFetchUserPermissionsQuery,
  useGetCurrentUserQuery,
} from "api/api-users-v2";
import { useVendorAddressMutation } from "api/api-vendors";
import slide1 from "assets/landing-page-sliders/slide-1.svg";
import slide2 from "assets/landing-page-sliders/slide-2.svg";
import slide3 from "assets/landing-page-sliders/slide-3.svg";
import slide4 from "assets/landing-page-sliders/slide-4.svg";
import InvoiceIcon from "assets/navigationIcons/invoice.svg?react";
import { DASHBOARD, REGISTERED, SUPPLIER } from "codes";
import AutoComplete from "components/Common/AutoComplete";
import DateInput from "components/Common/DateInput";
import Drop from "components/Common/Drop";
import LabelViewOnly from "components/Common/FormFields/LabelViewOnly";
import Input from "components/Common/Input";
import Layout from "components/Common/Layout";
import Modal from "components/Common/Modal";
import SkeletonLoad from "components/Common/SkeletonLoad";
import { FormikProps, useFormikContext } from "formik";
import { RegistrationFormFields } from "helpers/formFields";
import history from "helpers/history";
import isObjectEmpty from "helpers/isObjectEmpty";
import { roleRedirect } from "helpers/redirect";
import useIsMobile from "hooks/useIsMobile";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  setAppState,
  setUserPermission,
} from "redux/reducers/appStatesReducer";
import { setCurrentCompany } from "redux/reducers/companyReducer";
import { clearSteps, setSteps } from "redux/reducers/stepsReducer";
import type {
  Account,
  Country,
  RegisterCompany as IRegisterCompany,
  SalesCode,
  SearchCompany
} from "types";
import Goods from "../Goods";
import styles from "../addcompany.module.scss";

const RegisterCompany = () => {
    const formikProps: FormikProps<RegistrationFormFields & {
        sales_code: SalesCode ;
    }> = useFormikContext();

  //Route protection
  const APP_PERMISSION = useAppSelector((state) => state.appState.permission);
  if (APP_PERMISSION !== "") roleRedirect(APP_PERMISSION);

  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();
  const steps = useAppSelector((state) => state.steps.value);
  const theme = useTheme();
  const [showResetModal, setShowResetModal] = useState(false);

  const [filteredCompanies, setFilteredCompanies] = useState<SearchCompany[]>();
  const {
    crStage,
    foundCompanies,
    iso3,
    country,
    companyNameInitial,
    registrationNumber,
    companyId,
  } = formikProps?.values;

  const { data: company } = useGetAccountQuery(companyId, {
    skip: !Boolean(companyId),
  });
  const { data: user } = useGetCurrentUserQuery();
  const { errors, setErrors, validateForm, resetForm } = formikProps;

  const { data: userPermission } = useFetchUserPermissionsQuery(
    user?.id ?? "",
    {
      skip: !Boolean(user),
    }
  );

  const { data: { codes: salesCodes, default: defaultSalesCode } = {} } = useGetSalesCodesQuery(null)

  const disabled =
    formikProps.values.country === "" ||
    formikProps.values.registrationNumber === "" ||
    formikProps.values.companyNameInitial === "";
  //Get all countries initially
  const { data: countries } = useGetCountriesByTypeQuery("");

  const [updateSteps] = useUpdateStepsMutation();
  const [updateCompany, { isLoading: isLoadingUpdateCompany }] =
    useUpdateAccountMutation();
  const [registerCompany, { isLoading: isLoadingRegisterCompany }] =
    useCreateAccountMutation();

  const [vendorAddress] = useVendorAddressMutation();

  const setCompany = (item: SearchCompany) => {
    //add this address to DB on set
    vendorAddress({
      iso3: formikProps.values.iso3,
      registrationNumber: item.registration_number || registrationNumber,
      city: item.city,
      postcode: item.postal_code,
      address_line1: item.address_line_1,
      address_line2: item.address_line_1,
    });

    formikProps.setFieldValue("crStage", 2);
    document.getElementById("registration-step")?.scrollIntoView(true);

    const valuesFromIdenfy = {
      registrationNumber: item.registration_number || registrationNumber,
      city: item.city,
      name: item.name,
      companyNameInitial: item.name,
      postcode: item.postal_code,
      address_line1: item.address_line_1,
      address_line2: item.address_line_2,
    };

    const updatedForm = {
      ...steps.form,
      ...formikProps.values,
      is_identified: true,
      crStage: 2,
      ...valuesFromIdenfy,
    };
    formikProps.setValues({
      ...formikProps.values,
      is_identified: true,
      crStage: 2,
      ...valuesFromIdenfy,
    });
    dispatch(
      setSteps({
        ...steps,
        form: updatedForm,
      })
    );
  };

  const modalOverlaySx = {
    backgroundColor: "white !important",
    width: "50%",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "4ch",
    padding: "2ch",
    fontSize: `${theme.typography.fontSize}px !important`,
    fontFamily: `${theme.typography.fontFamily} !important`,
    height: "inherit !important",
    fontWeight: "inherit !important",
  };
  const modalSx = {
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  };

  const onClick = () => {
    formikProps.setFieldValue("name", companyNameInitial);
    formikProps.setFieldValue("crStage", 2);
    document.getElementById("registration-step")?.scrollIntoView(true);
    const companyType =
      formikProps.values.companyType === t("supplier") ||
      formikProps.values.companyType === SUPPLIER
        ? SUPPLIER
        : formikProps.values.companyType;

    dispatch(
      setSteps({
        ...steps,
        form: {
          ...steps.form,
          ...formikProps.values,
          companyType,
          crStage: 3,
        },
        step: 0,
      })
    );
  };
  const setter = (value: string) => {
    const selected = countries?.find((item: any) => item.name === value);
    formikProps.setFieldValue("country", selected?.name, true);
    formikProps.setFieldValue("iso3", selected?.id, true);
  };
  const { data: industries, isLoading: isLoadingIndustries } =
    useGetIndustriesQuery();

  const createCompany = () => {
    const { values } = formikProps;
    const { companyId: id } = values;

    const valueCleaner = (val: string): number => {
      return Number(val?.replaceAll(",", ""));
    };

    const registrationRequestBody: IRegisterCompany = {
      name: values?.name,
      reg_no: values?.registrationNumber,
      address_line1: values?.address_line1,
      address_line2: values?.address_line2 ?? "  ",
      city: values?.city,
      country: values?.iso3,
      postcode: values?.postcode,
      website: values?.website,
      is_identified: values?.is_identified ?? false,
      incorporation_date: values?.incorporation_date
        ? moment(values?.incorporation_date).format("YYYY-MM-DD")
        : undefined,
      annual_revenue:
        typeof values?.annual_revenue === "string"
          ? valueCleaner(`${values?.annual_revenue}`)
          : values?.annual_revenue,
      industry: values?.industry,
      goods_category: values?.goods_category,
      client: true,
      sales_code: values?.sales_code?.code
    };
    if (id || company?.id) {
      const updateCompanyBody: Partial<Account> = {
        ...registrationRequestBody,
        id,
        incorporation_date: registrationRequestBody.incorporation_date as Date,
        status: REGISTERED,
      };

      updateCompany(updateCompanyBody)
        .unwrap()
        .then((res) => {
          if (!res?.hasOwnProperty("call_error")) {
            toast(`${t("updated-company-details")}`, {
              type: "success",
            });

            dispatch(
              setCurrentCompany({
                ...res,
                type: values?.companyType,
              })
            );
          } else {
            toast(
              `${t("provide-required-information")}: ${(<br />)} ${
                !isObjectEmpty(errors) && (
                  <ul>
                    {Object.values(errors)?.map((err) => <li>{`${err}`}</li>)}
                  </ul>
                )
              }`,
              {
                type: "error",
              }
            );
            setErrors((res as any)["call_error"]);
          }
        })
        .catch((e) => {
          toast(e?.message ?? "An error occured", {
            type: "error",
          });
        });
    } else {
      registerCompany(registrationRequestBody)
        .unwrap()
        .then((res) => {
          if (!res?.hasOwnProperty("call_error")) {
            const company_id = res?.id;
            setErrors({});
            dispatch(setAppState(REGISTERED));
            userPermission?.[0]?.permission &&
              dispatch(setUserPermission(userPermission?.[0]?.permission));
            formikProps.setFieldValue("companyId", company_id, true);
            toast(`${t("successfully-registered-your-company")}`, {
              type: "success",
            });
            dispatch(
              setCurrentCompany({
                ...res,
                type: values?.companyType,
              })
            );
            history.push(DASHBOARD);
            updateSteps({
              company_id,
              form: {
                ...res,
                ...formikProps.values,
                companyId: company_id,
                crStage: 3,
              },
              step: 0,
            })
              .then(() => {
                dispatch(
                  setSteps({
                    form: {
                      ...res,
                      ...formikProps.values,
                      companyId: company_id,
                      crStage: 3,
                    },
                    step: 0,
                  })
                );
              })
              .catch((error) => {
                const errorFile = error?.message;
                toast(`Something went wrong : ${errorFile}`, {
                  type: "error",
                });
              });
          } else {
            const Toast = () => {
              if (!isObjectEmpty(errors))
                return (
                  <ul>
                    {Object.values(errors).map((err) => (
                      <li key={`${err}`}>{`${err}`}</li>
                    ))}
                  </ul>
                );
              return <>{t("provide-required-information")}</>;
            };

            toast(<Toast />, {
              type: "error",
            });
            setErrors((res as any)["call_error"]);
          }
        })
        .catch((e) => {
          toast(e?.message ?? "An error occured", {
            type: "error",
          });
        });
    }
  };

  return (
    // #TODO - Pending translation
    <Layout
      title="Incorporation Details"
      textHeadingOptions={{
        fontWeight: "bolder",
        level: 2,
        fontSize: isMobile ? "1.2em" : "1.5em",
      }}
      headerConfig={{
        left: 12,
        mid: 12,
        right: 0,
        xs: {
          left: 12,
          mid: 0,
          right: 0,
        },
        alignItems: "center",
      }}
      LayoutHeader={
        <div className={styles.creativeShape}>
          {/* Animated shapes */}
          <div className={styles.shape1}>
            <InvoiceIcon
              style={{
                width: "10ch",
                height: "10ch",
                fill: theme.palette.inactive.main,
                opacity: "0.15",
              }}
            />
          </div>
          <div className={styles.shape2}>
            <InvoiceIcon
              style={{
                width: "10ch",
                height: "10ch",
                fill: theme.palette.inactive.main,
                opacity: "0.15",
              }}
            />
          </div>
          <div className={styles.shape3}>
            <InvoiceIcon
              style={{
                width: "10ch",
                height: "10ch",
                fill: theme.palette.inactive.main,
                opacity: "0.15",
              }}
            />
          </div>

          <div className={`${styles.shape8} ${styles.rotateme}`}>
            <InvoiceIcon
              style={{
                width: "10ch",
                height: "10ch",
                fill: theme.palette.inactive.main,
                opacity: "0.15",
              }}
            />
          </div>
          <div className={styles.shape9}>
            <InvoiceIcon
              style={{
                width: "10ch",
                height: "10ch",
                fill: "#a2a6c3",
                opacity: "0.15",
              }}
            />
          </div>
        </div>
      }
    >
      <Grid item xs={12} lg={8} margin="auto">
        <Carousel
          autoPlay
          showThumbs={false}
          infiniteLoop
          centerSlidePercentage={50}
        >
          <img src={slide1} alt="register-for-financing" />
          <img src={slide2} alt="register-for-financing" />
          <img src={slide3} alt="register-for-financing" />
          <img src={slide4} alt="register-for-financing" />
        </Carousel>
      </Grid>
      {Boolean(countries?.length) && (
        <Grid item xs={12} margin="auto">
          <Grid
            container
            justifyContent="center"
            alignContent="center"
            spacing={{ xs: 1, lg: 2 }}
          >
            <Grid item xs={12} lg={12}>
              <Input
                labelClassName={styles.labelClass}
                name="companyNameInitial"
                label={`${t("company-name")}`}
                disabled={foundCompanies && Boolean(foundCompanies.length)}
                placeholder={`${t("company-name")}`}
                fullWidth
                style={{ width: "100%" }}
                type="text"
                {...formikProps}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <Input
                labelClassName={styles.labelClass}
                name="registrationNumber"
                label={`${t("registration-number")}`}
                disabled={foundCompanies && Boolean(foundCompanies.length)}
                fullWidth
                placeholder={`${t("registration-number")}`}
                style={{ width: "100%" }}
                {...formikProps}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <LabelViewOnly
                label={`${t("country")}`}
                labelClassName={styles.labelClass}
              />
              <AutoComplete
                data={countries as Country[]}
                name="country"
                labelKey={"name" as any}
                value={formikProps.values.country}
                disabled={foundCompanies && Boolean(foundCompanies.length)}
                placeholder={`${t("select-country")}`}
                onChange={(event: any, newValue: any) => {
                  setter(newValue?.name);
                }}
                fullWidth
                {...formikProps}
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <LabelViewOnly
                label={`${t("sales-code")}`}
                labelClassName={styles.labelClass}
                helpText={`${t("sales-code-help-text")}`}
              />
              <AutoComplete
                data={salesCodes?.map((code) => ({
                  ...code,
                  code_name: `${code.code}${code.code === defaultSalesCode?.code ? " (default)" : ""}`
                })) ?? []}
                name="sales_code"
                labelKey="code_name"
                value={formikProps.values.sales_code ?? {
                  ...defaultSalesCode, 
                  code_name: defaultSalesCode ? `${defaultSalesCode.code} (default)` : ''
                }}
                placeholder={`${t("select-sales-code")}`}
                onChange={(event, code) => formikProps.setFieldValue("sales_code", code)}
                {...formikProps}
              />
            </Grid>
            {
              // isUninitialized &&
              crStage === 0 && (
                <Grid item margin="auto" xs={8} lg={3}>
                  <LoadingButton
                    children={`${t("next")}`}
                    disabled={disabled}
                    onClick={onClick}
                    loading={isLoadingUpdateCompany}
                    fullWidth
                    variant="contained"
                  />
                </Grid>
              )
            }
          </Grid>
        </Grid>
      )}

      {/* All remaining registration-step fields */}
      {crStage === 2 && (
        <Grid item xs={12} id="registration-step">
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              padding={
                isMobile
                  ? "2ch 1ch 0ch 2ch!important"
                  : "3ch 1ch 0ch 1ch !important"
              }
            >
              <Typography fontWeight="bold">
                {t("company-registered-address")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Input
                labelClassName={styles.labelClass}
                name="address_line1"
                label={`${t("address")} 1`}
                required
                placeholder={`${t("street")} 1`}
                style={{ width: "100%" }}
                {...formikProps}
              />
            </Grid>

            <Grid item xs={12}>
              <Input
                labelClassName={styles.labelClass}
                name="address_line2"
                label={`${t("address")} 2`}
                placeholder={`${t("street")} 2`}
                style={{ width: "100%" }}
                {...formikProps}
              />
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={1} justifyContent="space-between">
                <Grid item xs={12} lg={6}>
                  <Input
                    labelClassName={styles.labelClass}
                    name="city"
                    label={`${t("city")}`}
                    placeholder={`${t("city")}`}
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Input
                    labelClassName={styles.labelClass}
                    name="postcode"
                    placeholder={`${t("postcode")}`}
                    label={`${t("postcode")}`}
                    style={{ width: "100%" }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1} justifyContent="space-between">
                {/* <Grid item xs={12} lg={6}>
                  <Input
                    labelClassName={styles.labelClass}
                    name={"country"}
                    label={`${t("country")}`}
                    disabled
                    style={{ width: "100%" }}
                    {...formikProps}
                  />
                </Grid> */}
                <Grid item xs={12} lg={6}>
                  <DateInput
                    labelClassName={styles.labelClass}
                    name="incorporation_date"
                    label={`${t("company-incorporation-date")} `}
                    required
                    wrapperClassName={`${styles.datePickerWrapper} ${
                      Boolean(
                        formikProps.touched.incorporation_date &&
                          formikProps.errors.incorporation_date
                      )
                        ? "error"
                        : ""
                    }`}
                    style={{ width: "96%" }}
                    value={
                      formikProps.values.incorporation_date !== undefined
                        ? (new Date(
                            formikProps.values.incorporation_date
                          ) as Date)
                        : undefined
                    }
                    setFieldValue={formikProps.setFieldValue}
                    error={
                      formikProps.touched.incorporation_date
                        ? (formikProps.errors.incorporation_date as string)
                        : ""
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1} justifyContent="space-between">
                <Grid item xs={12} lg={12}>
                  <Input
                    labelClassName={styles.labelClass}
                    name="website"
                    placeholder={`${t("website")}`}
                    label={`${t("website")}`}
                    style={{ width: "100%" }}
                    {...formikProps}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Grid container>
                {industries && industries?.length && (
                  <Grid item xs={12}>
                    <>
                      {isLoadingIndustries && <SkeletonLoad bars={1} />}
                      {!isLoadingIndustries && (
                        <Drop
                          label="Industry"
                          labelClassName={styles.labelClass}
                          name="industry"
                          required
                          style={{ width: "100%" }}
                          keyValue="name"
                          data={industries}
                          placeholder="Select Industry"
                          error={Boolean(
                            formikProps.errors.industry &&
                              formikProps.touched.industry
                          )}
                          {...formikProps}
                        />
                      )}
                      {Boolean(
                        formikProps.errors.industry &&
                          formikProps.touched.industry
                      ) && (
                        <FormHelperText
                          error={Boolean(formikProps.errors.industry)}
                        >
                          {formikProps.errors.industry}
                        </FormHelperText>
                      )}
                    </>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Grid container>
                <Grid item xs={12}>
                  <Goods
                    industry={formikProps.values.industry ?? ""}
                    disabled={false}
                    formikProps={formikProps}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} lg={6}>
                  <Input
                    labelClassName={styles.labelClass}
                    name="annual_revenue"
                    label="Annual Revenue (in USD equivalent)"
                    required
                    type="money"
                    textfieldProps={{
                      InputProps: {
                        startAdornment: (
                          <InputAdornment position="start">USD</InputAdornment>
                        ),
                      },
                    }}
                    style={{ width: "100%" }}
                    {...formikProps}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                spacing={isMobile ? 1 : 0}
              >
                <Grid item xs={10} lg={3} margin={isMobile ? "auto" : 0}>
                  <Button
                    variant="contained"
                    onClick={() => setShowResetModal(true)}
                    color="error"
                    fullWidth
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={10} lg={3} margin={isMobile ? "auto" : 0}>
                  <LoadingButton
                    fullWidth
                    onClick={() => {
                      document
                        .getElementById("registration-step")
                        ?.scrollIntoView(true);

                      formikProps.setTouched(
                        {
                          name: true,
                          registrationNumber: true,
                          address_line1: true,
                          incorporation_date: true,
                          annual_revenue: true,
                          industry: true,
                        },
                        true
                      );
                      validateForm().then((res) => {
                        if (isObjectEmpty(res)) {
                          createCompany();
                        } else {
                          toast("Your input is invalid. Please update it.", {
                            type: "error",
                          });
                        }
                      });
                    }}
                    variant="contained"
                    loading={isLoadingUpdateCompany || isLoadingRegisterCompany}
                    type="button"
                  >
                    {`${t("submit")}`}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Modal
        message={
          <Grid item lg={12} xs={12}>
            {`${t("lose-uploaded-documents")}`}
          </Grid>
        }
        sx={modalSx}
        width={isMobile ? "90%" : "40%"}
        height="auto"
        modalContentSx={{ height: "auto" }}
        open={showResetModal}
        modalOverlaySx={modalOverlaySx}
        modalFooterSx={{ maxWidth: "100%" }}
        onClose={() => setShowResetModal(false)}
        primary={{
          variant: "contained",
          onClick: () => [
            resetForm(), //reset formik
            dispatch(clearSteps()), //reset step redux
            setShowResetModal(false),
          ],
          children: "Confirm",
        }}
        closeButton
        secondary={{
          variant: "outlined",
          onClick: () => setShowResetModal(false),
          children: `${t("cancel")}`,
        }}
      />
    </Layout>
  );
};
export default RegisterCompany;
