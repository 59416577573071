import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type { CLDetails, CreditLimit } from "types";
import type { RootState } from "../store";

// Define the initial state using that type
const initialState: {
  value: CLDetails[];
} = {
  value: [],
};

export const clSlice = createSlice({
  name: "creditLimits",
  // `createSlice` will infer the state type from the `initialState` argument
  // Use the PayloadAction type to declare the contents of `action.payload`
  initialState,
  //CRUD
  reducers: {
    setCreditLimits: (state, action: PayloadAction<CLDetails[]>) => {
      state.value = [...action.payload];
    },
    updateCLR: (state, action: PayloadAction<CLDetails>) => {
      state.value = [
        ...state.value.map((cl) => {
          if (cl.id === action.payload.id) return action.payload;
          return cl;
        }),
      ];
    },
    updateCreditLimitStatus: (state, action: PayloadAction<CreditLimit>) => {
      state.value = [
        ...state.value.map((cl) => {
          if (cl.id === action.payload.id) {
            const item = action.payload;
            return {
              ...cl,
              clStatus: item.status,
              action: { clStatus: item.status, id: item.id },
              item,
            };
          }
          return cl;
        }),
      ];
    },
    deleteCreditLimit: (state, action: PayloadAction<string>) => {
      state.value = state.value.filter(
        (creditLimit) => creditLimit.reqId !== action.payload
      );
    },
    clearAll: () => initialState,
  },
});

export const { setCreditLimits, deleteCreditLimit, clearAll } = clSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectCreditLimits = (state: RootState) =>
  state.creditLimits.value;

export default clSlice.reducer;
