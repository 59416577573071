import {
  Avatar,
  Container,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { useGetInvestmentCardQuery, useGetMapSummaryQuery } from "api/api-maps";
import Logo from "assets/monogram.svg?react";
import Carousel from "components/Common/Carousel";
import LabelViewOnly from "components/Common/FormFields/LabelViewOnly";
import Layout from "components/Common/Layout";
import NoData from "components/Common/NoData";
import { MapContext } from "pages/maps/map";
import React from "react";
import CallSection from "./CallSection";
import CriteriaCardTemplate from "./CriteriaCardTemplate";
import DealFeature from "./DealFeature";

const TableHeader = styled(TableCell)(() => ({
  fontWeight: "bold",
  fontSize: "1em",
  lineHeight: "1em",
  minWidth: "10em",
}));
const StyledTableCell = styled(TableCell)(({ theme, children }) => ({
  color:
    children === "Incomplete"
      ? theme.palette.error.main
      : theme.palette.primary.main,
  paddingLeft: 1,
  paddingTop: 2,
  paddingBottom: 2,
  fontSize: "1em",
}));

const FactSheetStatus = () => {
  const id = React.useContext(MapContext);
  const theme = useTheme();

  const { data: mapData } = useGetMapSummaryQuery(id ?? "", { skip: !id });

  const { data: investmentCard, refetch } = useGetInvestmentCardQuery(
    id ?? "",
    {
      skip: !id,
    }
  );

  const {
    deal_features: {
      insurance,
      track_record,
      order_size,
      advanced_amount,
      payment_term,
    } = {},
    deal_summary: { summary, supplier, buyer, product, currency } = {},
    finance_features: { supplier_features, buyer_features } = {},
    supplier_call,
    buyer_call,
  } = investmentCard ?? {};

  const dealFeatureSections = [
    {
      title: "Credit Insurance",
      content: insurance,
    },
    {
      title: "Track Record",
      content: track_record,
    },
    {
      title: "Order size",
      content: order_size,
    },
    {
      title: "Advanced Amount",
      content: advanced_amount,
    },
    {
      title: "Payment Terms",
      content: payment_term,
    },
  ];

  const relationshipDeets = "";

  return (
    <Stack
      spacing={1}
      sx={{
        width: "100%",
        alignItems: "center",
      }}
    >
      <Carousel
        items={[
          <CriteriaCardTemplate>
            <Layout
              title={
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={1}
                  sx={{
                    mt: -2,
                    maxWidth: "148mm",
                    flexWrap: "wrap",
                  }}
                >
                  <Typography fontSize="0.8em" fontWeight="bold">
                    Investment Criteria Card:
                  </Typography>
                  <Typography fontSize="0.8em" fontWeight="bold">
                    {mapData?.seller_name}
                  </Typography>
                  <Typography fontSize="0.8em">{" selling to "}</Typography>
                  <Typography fontWeight="bold" fontSize="0.8em">
                    {mapData?.buyer_name}
                  </Typography>
                </Stack>
              }
              headerConfig={{
                syncAction: refetch,
                left: 12,
                mid: 0,
                right: 0,
                startComponent: <Logo width="5%" height="auto" />,
                alignItems: "center",
              }}
            >
              <Stack
                spacing={2}
                sx={{
                  minHeight: "100%",
                }}
              >
                <Stack>
                  <Typography color="error" fontWeight="bold" fontSize="1.2em">
                    Deal Summary
                  </Typography>
                  {summary ? (
                    <Typography>{summary}</Typography>
                  ) : (
                    <NoData
                      text="-- Incomplete --"
                      sx={{
                        bgcolor: "#C0C0C0",
                        fontSize: "1.2em",
                        fontWeight: "bold",
                        lineHeight: "3em",
                        width: "98%",
                        pl: 1,
                        mt: "0.5em",
                      }}
                    />
                  )}
                </Stack>
                <Stack
                  direction="row"
                  width="100%"
                  justifyContent="space-between"
                  alignItems="baseline"
                  spacing={1}
                >
                  <Stack
                    width="100%"
                    spacing={0.5}
                    sx={{
                      height: "18ch",
                      bgcolor: "#a2a6c391",
                      border: "2px dashed #6087ac",
                      borderRadius: 1,
                      pb: 2,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "1.2em",
                        fontWeight: "bold",
                        lineHeight: "3em",
                        width: "99%",
                        pl: 1,
                        textAlign: "center",
                      }}
                    >
                      Origination Type
                    </Typography>
                    <Stack
                      direction="row"
                      width="100%"
                      spacing={0.5}
                      height="100%"
                      justifyContent="center"
                    >
                      <Avatar
                        variant="rounded"
                        sx={{
                          width: "30%",
                          height: "100%",
                          bgcolor:
                            product === "factoring" ? "#6087ac" : "#ffffff",
                          textAlign: "center",
                          fontWeight:
                            product === "factoring" ? "bold" : "normal",
                          color:
                            product === "factoring"
                              ? "#ffffff"
                              : theme.palette.primary.main,
                        }}
                      >
                        Factoring
                      </Avatar>
                      <Avatar
                        variant="rounded"
                        sx={{
                          width: "30%",
                          height: "100%",
                          bgcolor:
                            product === "reverse_factoring"
                              ? "#6087ac"
                              : "#ffffff",
                          textAlign: "center",
                          fontWeight:
                            product === "reverse_factoring" ? "bold" : "normal",
                          color:
                            product === "reverse_factoring"
                              ? "#ffffff"
                              : theme.palette.primary.main,
                        }}
                      >
                        Reverse Factoring
                      </Avatar>
                      <Avatar
                        variant="rounded"
                        sx={{
                          width: "30%",
                          height: "100%",
                          textAlign: "center",
                          fontWeight:
                            product === "in_transit" ? "bold" : "normal",
                          bgcolor:
                            product === "in_transit" ? "#6087ac" : "#ffffff",
                          color:
                            product === "in_transit"
                              ? "#ffffff"
                              : theme.palette.primary.main,
                        }}
                      >
                        Supply Chain Finance
                      </Avatar>
                    </Stack>
                  </Stack>
                  <Stack width="100%">
                    <Stack direction="row" alignItems={"center"}>
                      <LabelViewOnly
                        label={"Seller Country"}
                        sx={{
                          bgcolor: theme.palette.primary.main,
                          color: "white.main",
                          width: "20ch",
                          height: "5ch",
                          pl: 1,
                          py: 2,
                        }}
                      />
                      <Typography
                        sx={{
                          bgcolor: "#a2a6c391",
                          width: "20ch",
                          height: "5ch",
                          pl: 1,
                          alignItems: "center",
                          py: 2,
                          borderTopRightRadius: 5,
                          borderBottomRightRadius: 5,
                        }}
                      >
                        {supplier?.country}
                      </Typography>
                    </Stack>
                    <Stack direction="row" alignItems={"center"}>
                      <LabelViewOnly
                        label={"Buyer Country"}
                        sx={{
                          bgcolor: theme.palette.primary.main,
                          color: "white.main",
                          width: "20ch",
                          height: "5ch",
                          pl: 1,
                          alignItems: "center",
                          py: 2,
                        }}
                      />
                      <Typography
                        sx={{
                          bgcolor: "#a2a6c391",
                          width: "20ch",
                          height: "5ch",
                          pl: 1,
                          alignItems: "center",
                          py: 2,
                          borderTopRightRadius: 5,
                          borderBottomRightRadius: 5,
                        }}
                      >
                        {buyer?.country}
                      </Typography>
                    </Stack>
                    <Stack direction="row" alignItems={"center"}>
                      <LabelViewOnly
                        label={"Currency"}
                        sx={{
                          bgcolor: theme.palette.primary.main,
                          color: "white.main",
                          width: "20ch",
                          height: "5ch",
                          pl: 1,
                          py: 2,
                        }}
                      />
                      <Typography
                        sx={{
                          bgcolor: "#a2a6c391",
                          width: "20ch",
                          height: "5ch",
                          pl: 1,
                          alignItems: "center",
                          py: 2,
                          borderTopRightRadius: 5,
                          borderBottomRightRadius: 5,
                        }}
                      >
                        {currency ?? "USD"}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
                <Stack direction="row" spacing={1} width="100%">
                  <Stack spacing={1} width="100%">
                    <Typography
                      color="error"
                      fontWeight="bold"
                      fontSize="1.2em"
                    >
                      Supplier
                    </Typography>
                    {supplier?.summary ? (
                      <Typography>{supplier?.summary}</Typography>
                    ) : (
                      <NoData
                        text="-- Incomplete --"
                        sx={{
                          bgcolor: "#C0C0C0",
                          fontSize: "1.2em",
                          fontWeight: "bold",
                          lineHeight: "3em",
                          width: "98%",
                          pl: 1,
                          mt: "0.5em",
                        }}
                      />
                    )}
                  </Stack>
                  <Stack spacing={1} width="100%">
                    <Typography
                      color="error"
                      fontWeight="bold"
                      fontSize="1.2em"
                    >
                      Buyer
                    </Typography>
                    {buyer?.summary ? (
                      <Typography>{buyer?.summary}</Typography>
                    ) : (
                      <NoData
                        text="-- Incomplete --"
                        sx={{
                          bgcolor: "#C0C0C0",
                          fontSize: "1.2em",
                          fontWeight: "bold",
                          lineHeight: "3em",
                          width: "98%",
                          pl: 1,
                          mt: "0.5em",
                        }}
                      />
                    )}
                  </Stack>
                </Stack>
              </Stack>
            </Layout>
          </CriteriaCardTemplate>,
          <CriteriaCardTemplate>
            <Container
              sx={{
                mt: 2,
              }}
            >
              <Stack spacing={3}>
                <Stack spacing={1}>
                  <Typography color="error" fontWeight="bold" fontSize="1.2em">
                    Deal Features
                  </Typography>
                  <Stack direction="row" spacing={1}>
                    {dealFeatureSections.map((section, index) => (
                      <DealFeature
                        key={section.title}
                        title={section.title}
                        content={section.content}
                        index={index}
                      />
                    ))}
                  </Stack>
                </Stack>
                <Stack spacing={1}>
                  <Typography color="error" fontWeight="bold" fontSize="1.2em">
                    Finance Features
                  </Typography>
                  <TableContainer component={Paper}>
                    <Table
                      sx={{
                        minWidth: 650,
                        border: "1px solid #e0e0e0",
                        bgcolor: "background.default",
                      }}
                      size="small"
                    >
                      <TableHead>
                        <TableRow>
                          <TableHeader></TableHeader>
                          <TableHeader>Supplier</TableHeader>
                          <TableHeader>Buyer</TableHeader>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>Revenue</TableCell>
                          <StyledTableCell>
                            {supplier_features?.revenue ?? "Incomplete"}
                          </StyledTableCell>
                          <StyledTableCell>
                            {buyer_features?.revenue ?? "Incomplete"}
                          </StyledTableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>Net Profit Margin</TableCell>
                          <StyledTableCell>
                            {supplier_features?.net_profit_margin ??
                              "Incomplete"}
                          </StyledTableCell>
                          <StyledTableCell>
                            {buyer_features?.net_profit_margin ?? "Incomplete"}
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Operating Profit Margin</TableCell>
                          <StyledTableCell>
                            {supplier_features?.operating_profit_margin ??
                              "Incomplete"}
                          </StyledTableCell>
                          <StyledTableCell>
                            {buyer_features?.operating_profit_margin ??
                              "Incomplete"}
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Total Equity</TableCell>
                          <StyledTableCell>
                            {supplier_features?.total_equity ?? "Incomplete"}
                          </StyledTableCell>
                          <StyledTableCell>
                            {buyer_features?.total_equity ?? "Incomplete"}
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Debt to Equity</TableCell>
                          <StyledTableCell>
                            {supplier_features?.debt_to_equity ?? "Incomplete"}
                          </StyledTableCell>
                          <StyledTableCell>
                            {buyer_features?.debt_to_equity ?? "Incomplete"}
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Current ratio</TableCell>
                          <StyledTableCell>
                            {supplier_features?.current_ratio ?? "Incomplete"}
                          </StyledTableCell>
                          <StyledTableCell>
                            {buyer_features?.current_ratio ?? "Incomplete"}
                          </StyledTableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Stack>
              </Stack>
            </Container>
          </CriteriaCardTemplate>,
          <CriteriaCardTemplate>
            <Container
              sx={{
                mt: 2,
              }}
            >
              <Stack spacing={3}>
                <CallSection
                  title={"Supplier"}
                  name={supplier?.name}
                  product={product}
                  interviewees={supplier_call?.interviewees}
                  attendees={supplier_call?.ipl_participants}
                  date={supplier_call?.date}
                  call_summary={supplier_call?.call_summary}
                />

                <Stack>
                  <Typography fontWeight="bold" fontSize="1.2em">
                    Relationship with Suppliers/Buyers
                  </Typography>
                  {relationshipDeets ? (
                    <Typography>Stuff</Typography>
                  ) : (
                    <NoData
                      text="-- Incomplete --"
                      sx={{
                        bgcolor: "#C0C0C0",
                        fontSize: "1.2em",
                        fontWeight: "bold",
                        lineHeight: "3em",
                        width: "98%",
                        pl: 1,
                        mt: "0.5em",
                      }}
                    />
                  )}
                </Stack>
              </Stack>
            </Container>
          </CriteriaCardTemplate>,
          <CriteriaCardTemplate>
            <Container
              sx={{
                mt: 2,
              }}
            >
              <Stack spacing={3}>
                <CallSection
                  title={"Buyer"}
                  name={buyer?.name}
                  product={product}
                  interviewees={buyer_call?.interviewees}
                  attendees={buyer_call?.ipl_participants}
                  date={buyer_call?.date}
                  call_summary={buyer_call?.call_summary}
                />
              </Stack>
            </Container>
          </CriteriaCardTemplate>,
        ]}
      />
    </Stack>
  );
};

export default FactSheetStatus;
