import toCamelCase from "helpers/camelCase";
import isObjectEmpty from "helpers/isObjectEmpty";
import objectCamelcaser from "helpers/objectCamelcaser";
import { useEffect, useState } from "react";

interface PARAM_VALUES {
  [source: string]: {
    [field_name: string]: any;
  };
}
interface MapTemplate {
  [field_name: string]: {
    id: string;
    name: string;
    type: string;
    log_id: string;
    data: string | number | string[];
  };
}

interface CRITERIA_TYPE extends Record<string, any> {
  [key: string]: MapTemplate;
}

const useTradeTableValueGenerator = (
  CRITERIA_ENUM: string[],
  values?: PARAM_VALUES,
  template?: MapTemplate,
  isFetchingValues?: boolean
) => {
  const INITIAL_VALUES_AWAITING_API = CRITERIA_ENUM.reduce((acc, current) => {
    acc[current] = Object.keys(template ?? {})
      .map((item) => ({
        [toCamelCase(item)]: undefined,
      }))
      .reduce((inner_acc, obj) => {
        Object.keys(obj).forEach((key) => {
          inner_acc[key] = obj[key];
        });
        return inner_acc;
      }, {} as any);
    return acc;
  }, {} as CRITERIA_TYPE);

  const generateInitialValues = (valueObject?: PARAM_VALUES): CRITERIA_TYPE => {
    if (valueObject && !isObjectEmpty(valueObject)) {
      return Object.entries(INITIAL_VALUES_AWAITING_API).reduce(
        (acc, [CALL_TYPE, FIELDS]) => {
          const DB_HAS_VALUE = Object.prototype.hasOwnProperty.call(
            valueObject,
            CALL_TYPE
          );
          if (DB_HAS_VALUE) {
            const CURRENT_VALUE_OF_FIELDS = valueObject[CALL_TYPE];
            const CONSOLIDATED_FIELDS = {
              ...(FIELDS as any),
              ...objectCamelcaser(CURRENT_VALUE_OF_FIELDS),
            };
            acc[CALL_TYPE] = CONSOLIDATED_FIELDS;
          } else {
            acc[CALL_TYPE] = FIELDS as any;
          }
          return acc;
        },
        {} as CRITERIA_TYPE
      );
    }
    return INITIAL_VALUES_AWAITING_API;
  };

  const [backgroundPerCriteria, setBackgroundPerCriteria] =
    useState<CRITERIA_TYPE>(INITIAL_VALUES_AWAITING_API);

  useEffect(() => {
    setBackgroundPerCriteria(generateInitialValues(values));
  }, [isFetchingValues, template, values]);

  return backgroundPerCriteria;
};

export default useTradeTableValueGenerator;
