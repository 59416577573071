import { Stack } from "@mui/material";
import {
  useAddCompanyLogoMutation,
  useEditAccountWebsiteMutation,
  useEditOriginationAccountCommentsMutation,
  useGetCompanyLogoQuery,
} from "api/api-maps";
import placeholder from "assets/placeholder-company.png";
import MainCard from "components/Common/Cards/MainCard";
import ImageUpload from "components/Common/ImageUpload";
import VariableInput from "components/Common/VariableInput";
import { Form, Formik } from "formik";
import moment from "moment";
import { MapContext } from "pages/maps/map";
import React from "react";
import { toast } from "react-toastify";
import type { TradePartnerSection } from "types";

const TradePartnerInfo: React.FC<TradePartnerSection> = ({
  account_id,
  account_type,
  name,
  country,
  turnover,
  incorporation_date,
  website,
  trade_partner_comments,
}) => {
  const mapName = React.useContext(MapContext);
  const [editComments] = useEditOriginationAccountCommentsMutation();
  const [editWebsite] = useEditAccountWebsiteMutation();
  const editor = (field: string, value: any, friendlyLabel: string) => {
    if (value === null || value === undefined || value === "") return;
    mapName &&
      editComments({
        map_name: mapName,
        account_type: account_type,
        data: { [field]: value },
      })
        .unwrap()
        .then(() => {
          toast.success(`${friendlyLabel} Updated`);
        })
        .catch((err) => toast.error(`Error: ${err?.message}`));
  };
  const [uploadLogo] = useAddCompanyLogoMutation();
  const {
    data: companyLogo = {
      url: placeholder,
    },
    refetch,
  } = useGetCompanyLogoQuery(
    {
      map_name: mapName ?? "",
      account_type,
    },
    {
      skip: !mapName,
      refetchOnFocus: true,
      refetchOnReconnect: true,
      refetchOnMountOrArgChange: true,
    }
  );

  const formikWrapper = {
    initialValues: {
      name,
      country,
      turnover,
      incorporation_date,
      website,
      trade_partner_comments,
      logo: "",
    },

    onSubmit: console.log,
  };

  return (
    <MainCard
      title={`${name} - Trade Partner`}
      headerSx={{
        p: 1,
      }}
      contentSX={{
        p: 1,
        backgroundColor: "grey.300",
      }}
    >
      <Formik {...formikWrapper}>
        {(formik) => (
          <Form>
            <Stack spacing={2} p={2} component="form">
              <Stack direction="row" spacing={2} justifyContent="space-between">
                <Stack spacing={2}>
                  <VariableInput
                    name="country"
                    label="Country"
                    value={formik.values.country}
                    onChange={formik.handleChange}
                    disabled
                    noHover
                  />
                  <VariableInput
                    name="turnover"
                    label="Turnover"
                    value={formik.values.turnover}
                    onChange={formik.handleChange}
                    type="money"
                    disabled
                    noHover
                  />
                  <VariableInput
                    name="incorporation_date"
                    label="Incorporation Date"
                    value={
                      formik.values.incorporation_date
                        ? moment(formik.values.incorporation_date).format(
                            "DD/MM/YYYY"
                          )
                        : "Not Available"
                    }
                    onChange={formik.handleChange}
                    disabled
                    noHover
                  />
                </Stack>

                <ImageUpload
                  variant="rounded"
                  src={companyLogo?.url}
                  onChange={(e) => {
                    uploadLogo({
                      map_name: mapName ?? "",
                      account_type: account_type,
                      file: e.currentTarget.files?.[0] as File,
                    })
                      .unwrap()
                      .then(() => toast.success("Logo Uploaded"))
                      .catch((err) => toast.error(`Error: ${err?.message}`))
                      .finally(() => refetch());
                  }}
                  sx={{
                    width: "10rem",
                    height: "10rem",
                    borderRadius: 1,
                  }}
                />
              </Stack>

              <Stack
                spacing={2}
                sx={{
                  backgroundColor: "#ffffff",
                  borderRadius: 1,
                  padding: 1,
                  boxShadow: 1,
                }}
              >
                <VariableInput
                  name="website"
                  label="Website"
                  value={formik.values.website}
                  onChange={formik.handleChange}
                  handleSave={(value) => {
                    editWebsite({
                      account_id: account_id,
                      data: { website: value as string },
                    })
                      .unwrap()
                      .then(() => {
                        toast.success(`Website Updated`);
                      })
                      .catch((err) => toast.error(`Error: ${err?.message}`));
                  }}
                />

                <VariableInput
                  name="trade_partner_comments"
                  label="Comments of trade partner"
                  value={formik.values.trade_partner_comments}
                  onChange={formik.handleChange}
                  sx={{
                    ".MuiInputBase-root": {
                      height: "15vh",
                      backgroundClip: "border-box",
                      borderRadius: "10px",
                      textarea: {
                        height: "13vh !important",
                        overflow: "scroll !important",
                      },
                    },
                  }}
                  placeholder="Enter details here"
                  richText
                  handleSave={(value) => {
                    editor(
                      "trade_partner_comments",
                      value,
                      "Comments of trade partner"
                    );
                  }}
                  {...formik}
                />
              </Stack>
            </Stack>
          </Form>
        )}
      </Formik>
    </MainCard>
  );
};

export default TradePartnerInfo;
