import { FormControl, FormHelperText, TextField } from "@mui/material";
import valueCleaner from "helpers/valueCleaner";
import React from "react";
import { NumericFormat } from "react-number-format";
import Label from "./Label";

interface MoneyProps {
  className?: string;
  isError?: boolean;
  style?: React.CSSProperties;
  label?: string | React.ReactNode;
  tabIndex?: number;
  type?: string;
  name: string;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  textfieldProps?: any;
  value?: number;
  variant?: "outlined" | "filled" | "standard";
  sx?: any;
  allowNegative?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  onChange: (
    value: string | number | React.ChangeEvent<HTMLInputElement>
  ) => void;
  error?: string;
  decimalScale?: number;
}

const Money: React.FC<MoneyProps> = ({
  className,
  isError,
  style,
  label,
  tabIndex,
  type,
  name,
  onBlur,
  textfieldProps,
  value,
  variant,
  sx,
  allowNegative,
  disabled,
  fullWidth,
  onChange,
  error,
  decimalScale,
}) => {
  return (
    <FormControl className={className} error={isError} style={style}>
      {label && <Label label={label} />}
      <NumericFormat
        inputProps={{ tabIndex, "data-testid": `${type}-field-${name}` }}
        onBlur={onBlur}
        name={name}
        InputProps={textfieldProps?.["InputProps"]}
        customInput={TextField}
        decimalScale={decimalScale || 2}
        fixedDecimalScale={true}
        thousandSeparator
        value={valueCleaner(`${value}`)}
        variant={variant}
        sx={sx}
        margin="dense"
        allowNegative={allowNegative}
        disabled={disabled}
        error={isError}
        fullWidth={fullWidth}
        onChange={(e) => onChange(e?.target.value)}
        type="text"
      />
      {isError && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default Money;
